export default {
  title: 'Badges',
  subtitle: 'Earn Points and collect badges',
  image: 'icn-badges.svg',
  image_alt: 'Badges',
  bronze_award_content: 'Nice! You’ve got a bit of beast about you.',
  silver_award_content: 'Great! What a brilliant beast you are.',
  gold_award_content: 'Amazing! You’re the beasts’ best buddy.',
  body_content: '<p>As you explore the trails and answer questions,' +
    ' you’ll earn badges for each of the beasts and for spotting things' +
    ' on the I Spy list. Each badge comes in bronze, silver and gold.</p><p> ' +
    'Answer more questions right or spot more I Spy items to earn better badges. ' +
    'Good luck, Badge Hunters!</p>',
};
