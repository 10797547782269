export default {
  prompt_overlay: {
    title: 'I Spy',
    image: 'icn-eyespy.svg',
  },
  intro_overlay: {
    title: 'I Spy',
    subtitle: 'On the way, I’ve seen...',
    image: 'icn-eyespy.svg',
    body_content: '<p>There are all sorts of things to see as you explore the Highwalks. See if you can spot and tick off all the things on the I Spy list…</p>',
  },
  can_you_spot_overlay: {
    title: 'On the way, I’ve seen...',
    body_content: '<p>There are all sorts of things to see as you explore the Highwalks. See if you can spot and tick off all the things on the I Spy list…</p>',
    spots: [
      {

      },
    ],
  },
};
