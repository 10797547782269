export default {
  type: 'question',
  show_eye_spy_prompt: false,
  question: 'Who created The Minotaur?',
  answers: [
    'Michael Ayrton',
    'Basil Spence',
    'Thomas Benolt',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Michael Ayrton',
  number: 1,
};
