export default {
  type: 'question',
  number: 8,
  show_eye_spy_prompt: true,
  question: 'Can you find a map of the Barbican Estate? What lies in the grid G5?',
  answers: [
    'Salters’ Hall',
    'Museum of London',
    'Guildhall School of Music & Drama',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Guildhall School of Music & Drama',
};
