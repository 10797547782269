export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'I love scoffing old burgers! What’s sticking through the burger on the logo of the restaurant across the bridge?',
  answers: [
    'A Sword',
    'A Spear',
    'A Flag',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'A Sword',
  number: 14,
};
