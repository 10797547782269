export default {
  type: 'question',
  number: 1,
  show_eye_spy_prompt: false,
  question: 'Search Beech Gardens for a dolphin statue. How many dolphins are dancing together?',
  answers: [
    '2',
    '3',
    '4',
  ],
  available_points: [10, 5, 1],
  correct_answer: '2',

};
