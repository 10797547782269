export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Let’s head round to the left of the entrance near the Lunch Space. Can you find out when London’s first Indian Restaurant opened?',
  answers: [
    '1810',
    '1870',
    '1910',
  ],
  available_points: [10, 5, 1],
  correct_answer: '1810',
  number: 12,
};
