export default {
  type: 'question',
  number: 5,
  show_eye_spy_prompt: true,
  question: 'Beech Gardens is above ‘White Lyon Court’. Some of my South African cousins are white lions! Let’s test your lion knowledge. Do you know how fast lions can run?',
  answers: [
    '5 mph (we’re very lazy!)',
    '400mph (…honest!)',
    '49mph (we’re super fast!)',
  ],
  available_points: [10, 5, 1],
  correct_answer: '49mph (we’re super fast!)',
};
