import React from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';

const MenuItem = ({ avatar, selected, currentTrail }) => {
  const getTrailImagePath = () => require(`../assets/images/avatars/${avatar}`);

  return (
    <div className={`menu-item ${selected ? 'active' : ''} ${currentTrail.toLowerCase()}`}>
      <Image src={getTrailImagePath()} fluid />
    </div>
  );
};

MenuItem.propTypes = {
  avatar: PropTypes.string,
  selected: PropTypes.bool,
  currentTrail: PropTypes.string,
};

MenuItem.defaultProps = {
  avatar: '',
  selected: false,
  currentTrail: '',
};

export default MenuItem;
