import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import ReactSVG from 'react-svg';
import BodyContent from '../../shared/BodyContent';
import CloseIcon from '../../../assets/svgs/icn-cross.svg';

const BadgesIntroduction = (props) => {
  const { closeOverlay, badgeTrailDetails } = props;

  const badgeIntroductionImage = () => {
    if (badgeTrailDetails.image.length === 0) {
      return null;
    }

    const badgeIcon = () => {
      return require(`../../../assets/svgs/${badgeTrailDetails.image}`).toString();
    };
    return (
      <ReactSVG
        src={badgeIcon()}
        onClick={closeOverlay}
        className="badges-introduction__image overlay__image"
      />
    );
  };

  const badgeIntroductionText = <BodyContent extendedClass="badges-introduction__content body--copy" bodyContent={badgeTrailDetails.body_content} />;

  return (
    <Container className="badges-introduction overlay overlay--badges">
      <Row className="actions">
        <Col className="text-right">
          <ReactSVG
            src={CloseIcon}
            onClick={closeOverlay}
            className="icon-close"
          />
        </Col>
      </Row>
      <Row className="content">
        <Col className="xs-12">
          {badgeIntroductionImage()}
          <h1 className="header--one">{badgeTrailDetails.title}</h1>
          <hr />
          <h3 className="header--three">{badgeTrailDetails.subtitle}</h3>
          {badgeIntroductionText}
        </Col>
      </Row>
    </Container>
  );
};

BadgesIntroduction.propTypes = {
  closeOverlay: PropTypes.func,
  badgeTrailDetails: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    image_alt: PropTypes.string,
    body_content: PropTypes.string,
    badges: PropTypes.array,
  }),
};

BadgesIntroduction.defaultProps = {
  closeOverlay: () => {},
  badgeTrailDetails: {},
};

export default BadgesIntroduction;
