import React, { Component } from 'react';
import Modal from 'react-modal';
import EyeSpyIntroduction from './EyeSpyIntroduction';
import EyeSpy from './EyeSpy';
import { GlobalContext } from '../../../store/global';
import ApplicationJson from '../../../json/application';

const EyeSpyOverlay = class EyeSpyOverlay extends Component {
  static contextType = GlobalContext;

  state = {
    showEyeSpyContent: false,
  };

  setEyeSpyShowContent = (show) => {
    this.setState({
      showEyeSpyContent: show,
    });
    this.forceUpdate();
  };

  close = () => {
    const { setEyeSpyOverlayToggle } = this.context;

    setEyeSpyOverlayToggle(false);
    this.setEyeSpyShowContent(true);
  };

  closeEyeSpyIntroduction = () => {
    this.setEyeSpyShowContent(true);
  }

  showHelpfulHint = () => {
    this.setEyeSpyShowContent(false);
  }

  renderEyeSpyContent = () => {
    const { showEyeSpyContent } = this.state;
    if (showEyeSpyContent) {
      return (
        <EyeSpy
          closeOverlay={this.close}
          setShowContent={this.setEyeSpyShowContent}
          eyeSpies={ApplicationJson.eye_spy}
          showHelpfulHint={this.showHelpfulHint}
        />
      );
    }

    return (
      <EyeSpyIntroduction
        closeOverlay={this.closeEyeSpyIntroduction}
        setShowContent={this.setEyeSpyShowContent}
        eyeSpies={ApplicationJson.screens.eye_spy}
      />
    );
  };

  render() {
    const { eyeSpyOverlay } = this.context;

    return <Modal isOpen={eyeSpyOverlay} className="Modal">{this.renderEyeSpyContent()}</Modal>;
  }
};

export default EyeSpyOverlay;
