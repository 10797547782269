import L1 from './locations/location_1';
import L2 from './locations/location_2';
import L3 from './locations/location_3';
import L4 from './locations/location_4';
import L5 from './locations/location_5';
import L6 from './locations/location_6';
import L7 from './locations/location_7';
import L8 from './locations/location_8';

import F1 from './fun_facts/fun_facts_1';
import F2 from './fun_facts/fun_facts_2';
import F3 from './fun_facts/fun_facts_3';
import F4 from './fun_facts/fun_facts_4';
import F5 from './fun_facts/fun_facts_5';

import Q1 from './questions/question_1';
import Q2 from './questions/question_2';
import Q3 from './questions/question_3';
import C1 from './challenges/challenge_1';
import Q4 from './questions/question_4';
import Q5 from './questions/question_5';
import Q6 from './questions/question_6';
import Q7 from './questions/question_7';
import Q8 from './questions/question_8';
import Q9 from './questions/question_9';
import C2 from './challenges/challenge_2';
import Q10 from './questions/question_10';
import Q11 from './questions/question_11';
import Q12 from './questions/question_12';
import Q13 from './questions/question_13';
import Q14 from './questions/question_14';
import Q15 from './questions/question_15';
import Q16 from './questions/question_16';
import Badges from './badges';

export default {
  id: 1,
  title: 'Fox',
  avatar: 'fox-avatar.png',
  map: 'fox.png',
  summary_body_content: 'Explore the Highwalks from the Museum of London to the Barbican Centre with the Fox as your guide.',
  detail_body_content: 'On this trail, the Fox will guide you on a journey from the Museum of London, around the Highwalks, to the Barbican Centre. Will you be able to show your cunning and answer all of the Fox’s questions? Tap the \'Eye\' symbol to tick off interesting things you see along the way - and win I Spy points!',
  introduction_body_content: 'Oh, hello there. You’re new! Would you like to come exploring with me? There’s lots to sniff out as we walk along the Highwalks from the Museum of London to the Barbican Centre.',
  time_to_complete: 40,
  distance: 1,
  colors: {
    primary: '#bb490f',
    secondary: '#fa732e',
    secondary_tint: 'rgba(249, 98, 21, 0.3)',
    black_tint: 'rgba(0, 0, 0, 0.3)',
  },
  totalQuestions: 16,
  totalPoints: 180,
  questions: {
    1: L1,
    2: Q1,
    3: Q2,
    4: Q3,
    5: F1,
    6: C1,
    7: Q4,
    8: Q5,
    9: Q6,
    10: L2,
    11: Q7,
    12: F2,
    13: L3,
    14: Q8,
    15: L4,
    16: L5,
    17: Q9,
    18: F3,
    19: L6,
    20: L7,
    21: L8,
    22: Q10,
    23: F4,
    24: Q11,
    25: Q12,
    26: C2,
    27: Q13,
    28: Q14,
    29: Q15,
    30: Q16,
    31: F5,
  },
  badges: Badges.badges,
};
