export default {
  type: 'question',
  number: 4,
  show_eye_spy_prompt: false,
  question: 'Which composer sat under the trees of Burnham Beeches to gain inspiration? Hunt around Beech Gardens to find out.',
  answers: [
    'Franz Liszt',
    'Richard Wagner',
    'Felix Mendelssohn',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Felix Mendelssohn',
};
