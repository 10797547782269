export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Can you spot the giant golden statue? How many eyes can you count on it?',
  answers: [
    '6',
    '2',
    '4',
  ],
  available_points: [10, 5, 1],
  correct_answer: '6',
  number: 16,
};
