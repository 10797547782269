import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { GlobalContext } from '../../store/global';
import MileIcon from '../../assets/svgs/cm-logo-neg-rgb.svg';
import BackIcon from '../../assets/svgs/arw-left-white.svg';

const LeftHandNavigationComponent = (props) => {
  const {
    setWarningOverlayToggle,
  } = useContext(GlobalContext);
  const { showLogo, location, history } = props;

  const showWarningRegex = new RegExp('^/trail/([a-z]+)/?$');

  const trailIntroductionRegex = new RegExp('^/trail-introduction/([a-z]+)/?$');

  const showWarning = () => {
    if (location.pathname.match(showWarningRegex) !== null) {
      setWarningOverlayToggle(true);

      return;
    }

    if (location.pathname.match(trailIntroductionRegex) !== null) {
      return history.push('/trail-detail');
    }

    switch(location.pathname) {
      case '/select-trail':
        return history.push('/');
        break;
      case '/trail-detail':
        return history.push('/select-trail');
        break;
      default:
        return history.goBack();
    }

  };

  const renderLeftHandSideIcon = () => {
    if (showLogo === true || location.pathname.match(showWarningRegex) !== null) {
      return (
        <ReactSVG
          src={MileIcon}
          className="mile-icon"
          beforeInjection={svg => {
            svg.classList.add('logo');
          }}
        />
      );
    }
    return (
      <ReactSVG
        src={BackIcon}
        beforeInjection={svg => {
          svg.classList.add('nav-link');
          svg.classList.add('pl-0');
          svg.classList.add('pr-0');
          svg.classList.add('mr-3');
          svg.classList.add('back-button');
          svg.setAttribute('style', 'width: 16px');
          svg.setAttribute('style', 'height: 40px');
        }}
        onClick={showWarning}
      />
    );
  };

  return (
    <Fragment>
      {renderLeftHandSideIcon()}
    </Fragment>
  );
};

LeftHandNavigationComponent.propTypes = {
  showLogo: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

LeftHandNavigationComponent.defaultProps = {
  showLogo: true,
  history: {},
  location: {},
};

export default withRouter(LeftHandNavigationComponent);
