export default {
  type: 'question',
  show_eye_spy_prompt: false,
  question: 'Look at the gates at the entrance to the park. How many golden birds can you see on these beautiful gates? ',
  answers: [
    '6',
    '2',
    '12',
  ],
  available_points: [10, 5, 1],
  correct_answer: '6',
  number: 2,
};
