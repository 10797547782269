export default {
  type: 'question',
  number: 11,
  show_eye_spy_prompt: true,
  question: 'As you peer into the Museum of London’s gardens, can you see the green roof tops? Why are green roofs good for the city?',
  answers: [
    'They’re not, it’s just weeds!',
    'They help drain rainwater away',
    'They’re actually fake turf',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'They help drain rainwater away',
};
