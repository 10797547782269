export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Can you look around for the statue of a horse? Have a look at the information sign by the horse. When was Christopher Le Brun born?',
  answers: [
    '1951',
    '1901',
    '1851',
  ],
  available_points: [10, 5, 1],
  correct_answer: '1951',
  number: 11,
};
