export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'I love roaming the streets of London. Look into the Museum entrance through the glass. What street name CAN’T you see on the sign post? ',
  answers: [
    'Baker Street',
    'Old Seacole Lane',
    'Strand Street',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Baker Street',
  number: 6,
};
