export default {
  key: 'eye_spy',
  image: 'svgs/icn-eyespy.svg',
  badges: {
    bronze: {
      image: 'svgs/menu-icn-eye.svg',
      score: 5,
    },
    silver: {
      image: 'svgs/menu-icn-eye.svg',
      score: 7,
    },
    gold: {
      image: 'svgs/menu-icn-eye.svg',
      score: 9,
    },
  },
};
