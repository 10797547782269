import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const BodyContent = (props) => {
  const { bodyContent, extendedClass } = props;

  if (bodyContent.length < 1) {
    return null;
  }
  return (
    <div className={`body--content ${extendedClass}`}>
      {parse(bodyContent)}
    </div>
  );
};

BodyContent.propTypes = {
  bodyContent: PropTypes.string,
  extendedClass: PropTypes.string,
};

BodyContent.defaultProps = {
  bodyContent: () => '',
  extendedClass: () => '',
};

export default BodyContent;
