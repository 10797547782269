export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Wow, what great ruins to have a run about in. What was the name of this tower?',
  answers: [
    'Elsyng Spital',
    'Aisling Spootal',
    'Ooling Spatal',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Elsyng Spital',
  number: 5,
};
