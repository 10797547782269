import EyeSpyBadges from '../eyespy/badges';
import LionTrailBadges from '../trails/trail_1/badges';
import FoxTrailBadges from '../trails/trail_2/badges';
import DormouseTrailBadges from '../trails/trail_3/badges';

export default [
  EyeSpyBadges,
  FoxTrailBadges,
  LionTrailBadges,
  DormouseTrailBadges,
];
