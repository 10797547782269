export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Peer over the side of the bridge, opposite the museum entrance. Can you spot a zebra crossing or two? How many white stripes are there?',
  answers: [
    '10',
    '5',
    'none',
  ],
  available_points: [10, 5, 1],
  correct_answer: '10',
  number: 10,
};
