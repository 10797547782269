export default {
  type: 'question',
  show_eye_spy_prompt: false,
  question: 'You’ll have to look carefully to find the answer to this question. What did children in the Middle Ages drink because it was safer than dirty water?',
  answers: [
    'Weak beer',
    'Cloudy lemonade',
    'Carrot juice',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Weak beer',
  number: 1,
};
