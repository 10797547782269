import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Image } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import { GlobalContext } from '../../../store/global';
import ApplicationJson from '../../../json/application';

const Badge = (props) => {
  const { myBadges } = useContext(GlobalContext);
  const { badge, currentBadgeAwarded } = props;

  const getBadgesMainImage = () => {
    return require(`../../../assets/${badge.image}`);
  };

  const badgeLevelSVG = (svg) => {
    return require(`../../../assets/${svg}`).toString();
  };

  const doesUserHaveBadge = (currentBadge, trailKey) => {
    const result = myBadges[trailKey] !== undefined
      ? myBadges[trailKey][currentBadge]
      : false;

    if (result === true) {
      return 'achieved';
    }
    return '';
  };

  const showBadgeMessage = () => {
    if (currentBadgeAwarded !== '') {
      if (currentBadgeAwarded === 'bronze') {
        return (
          <p>{ApplicationJson.screens.badges.bronze_award_content}</p>
        );
      }
      if (currentBadgeAwarded === 'silver') {
        return (
          <p>{ApplicationJson.screens.badges.bronze_award_content}</p>
        );
      }
      if (currentBadgeAwarded === 'gold') {
        return (
          <p>{ApplicationJson.screens.badges.bronze_award_content}</p>
        );
      }
    }
    return null;
  };

  return (
    <div className={`badge-item ${badge.key}`}>
      <Row className="game-badge">
        <div className="game-badge__image-wrapper">
          <div className="badge-item__main-image">
            <Image src={getBadgesMainImage()} fluid />
          </div>
        </div>
        <div className="game-badge__score">
          <Row className="game-badge__badges">
            {showBadgeMessage()}
            {
              Object.keys(badge.badges).map((key, i) => {
                return (
                  <div className={`game-badge__individual-badge  ${key} ${doesUserHaveBadge(key, badge.key)}`} key={`${badge.key}-${key}-wrapper`}>
                    <ReactSVG
                      key={`${badge.key}-${key}`}
                      src={badgeLevelSVG(badge.badges[key].image)}
                      className={`badge-icon ${key} ${doesUserHaveBadge(key, badge.key)}`}
                    />
                  </div>
                );
              })
            }
          </Row>
        </div>
      </Row>
    </div>
  );
};

Badge.propTypes = {
  badge: PropTypes.objectOf(PropTypes.any),
  currentBadgeAwarded: PropTypes.string,
};

Badge.defaultProps = {
  badge: [],
  currentBadgeAwarded: '',
};

export default Badge;
