export default {
  type: 'question',
  number: 12,
  show_eye_spy_prompt: true,
  question: 'Ah, now a question about a great warrior! What did Milo the Cretonian use to slay an Ox? You may need to squint to spot the answer!',
  answers: [
    'His fist',
    'An axe',
    'A bow and arrow',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'His fist',
};
