import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MultipleChoiceQuestion from '../components/game/MultipleChoiceQuestion';
import ChallengeQuestion from '../components/game/ChallengeQuestion';
import { GlobalContext } from '../store/global';
import NextLocation from '../components/game/NextLocation';
import FunFact from '../components/game/FunFact';

const Game = class Game extends Component {
  static contextType = GlobalContext;

  state = {
    currentQuestion: 1,
    attempts: 0,
    selectedAnswer: null,
    answersGiven: [],
    correct: null,
    points: 0,
    shuffled: false,
    showAwardedBadgeOnNextScreen: false,
  };

  static propTypes = {
    trails: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.shape({
      params: PropTypes.shape({
        trail: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  static defaultProps = {
    trails: {},
    match: {},
    history: {},
  };

  componentWillMount() {
    const {
      setSelected,
      pointsEarned,
      setInitialPointsEarnedState,
      clearCurrentPointsEarned,
      myBadges,
      setInitialBadgeState,
      setCurrentAwardedBadge,
    } = this.context;
    const { trails, match } = this.props;
    const pointsEarnedForCurrentTrail = pointsEarned[match.params.trail];

    const currentIndex = (trails.findIndex(obj => obj.key === match.params.trail) + 1);
    setCurrentAwardedBadge(null);
    setSelected(currentIndex);

    if (pointsEarnedForCurrentTrail === undefined) {
      setInitialPointsEarnedState(match.params.trail);
    }

    if (pointsEarnedForCurrentTrail !== undefined
      && pointsEarnedForCurrentTrail.current > 0) {
      clearCurrentPointsEarned(match.params.trail);
    }

    if (myBadges[match.params.trail] === undefined) {
      setInitialBadgeState(match.params.trail);
    }
  }

  componentDidMount() {
    window.onpopstate = this.onBackButtonEvent;
  }

  componentWillUpdate() {
  }

  componentDidUpdate() {
    const { pointsEarned, myBadges } = this.context;

    localStorage.setItem('points-earned', JSON.stringify(pointsEarned));
    localStorage.setItem('my-badges', JSON.stringify(myBadges));
  }

  updateGameState = (state) => {
    this.setState(state);
  };

  onBackButtonEvent = (event) => {
    const { history } = this.props;

    event.preventDefault();
    const {
      setWarningOverlayToggle,
    } = this.context;
    setWarningOverlayToggle(true);
    history.go(1);
  }

  renderQuestionType = () => {
    const { selected, getSelectedTrail } = this.context;
    const { currentQuestion } = this.state;
    const { trails } = this.props;

    const selectedTrail = selected !== null ? trails[getSelectedTrail] : null;

    const questionType = selected !== null
      ? trails[getSelectedTrail].data.questions[currentQuestion].type
      : null;

    const questionTypes = {
      fun_fact: FunFact,
      location: NextLocation,
      challenge: ChallengeQuestion,
      question: MultipleChoiceQuestion,
    };
    if (questionTypes[questionType] === undefined) {
      return null;
    }
    const ComponentName = questionTypes[questionType];

    return (
      <ComponentName
        gameState={this.state}
        currentTrail={selectedTrail}
        updateGameState={this.updateGameState}
      />
    );
  };

  render() {
    return this.renderQuestionType();
  }
};

export default Game;
