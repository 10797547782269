export default {
  type: 'question',
  number: 13,
  show_eye_spy_prompt: true,
  question: 'Search around the horse statue for an information sign... Who created the statue?',
  answers: [
    'Christopher Le Brun',
    'John Wesley',
    'Milo the Cretonian',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Christopher Le Brun',
};
