import image1 from '../../assets/images/i-spy/ES1.jpg';
import image2 from '../../assets/images/i-spy/ES2.jpg';
import image3 from '../../assets/images/i-spy/ES3.jpg';
import image4 from '../../assets/images/i-spy/ES4.jpg';
import image5 from '../../assets/images/i-spy/ES5.jpg';
import image6 from '../../assets/images/i-spy/ES6.jpg';
import image7 from '../../assets/images/i-spy/ES7.jpg';
import image8 from '../../assets/images/i-spy/ES8.jpg';
import image9 from '../../assets/images/i-spy/ES9.jpg';
import image10 from '../../assets/images/i-spy/ES10.jpg';
import image11 from '../../assets/images/i-spy/ES11.jpg';
import image12 from '../../assets/images/i-spy/ES12.jpg';
import image13 from '../../assets/images/i-spy/ES13.jpg';
import image14 from '../../assets/images/i-spy/ES14.jpg';
import image15 from '../../assets/images/i-spy/ES15.jpg';

export default [
  {
    id: 1,
    image: image1,
    content: 'A barber’s shop sign',
  },
  {
    id: 2,
    image: image2,
    content: 'City of London Crest',
  },
  {
    id: 3,
    image: image3,
    content: 'A bee',
  },
  {
    id: 4,
    image: image4,
    content: 'A fountain',
  },
  {
    id: 5,
    image: image5,
    content: 'A church clock',
  },
  {
    id: 6,
    image: image6,
    content: 'A seagull',
  },
  {
    id: 7,
    image: image7,
    content: 'A coot',
  },
  {
    id: 8,
    image: image8,
    content: 'A pigeon',
  },
  {
    id: 9,
    image: image9,
    content: 'A swimming fish',
  },
  {
    id: 10,
    image: image10,
    content: 'Barbican Centre floor tile',
  },
  {
    id: 11,
    image: image11,
    content: 'Coloured Vent',
  },
  {
    id: 12,
    image: image12,
    content: 'A Silver Jubilee floor tile',
  },
  {
    id: 13,
    image: image13,
    content: 'A Squirrel',
  },
  {
    id: 14,
    image: image14,
    content: 'A red London bus',
  },
  {
    id: 15,
    image: image15,
    content: 'A gravestone',
  },
];
