export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'From about halfway across the bridge, look off to the side on your left as you walk towards the Barbican Centre. In what order are the colours on the Barbican flags beside the lake?',
  answers: [
    'Yellow, Green, Blue and Red',
    'Orange, Yellow, Green, Blue',
    'Red, Yellow, Blue, Purple',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Yellow, Green, Blue and Red',
  number: 12,
};
