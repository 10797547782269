import React, { Component } from 'react';
import Modal from 'react-modal';
import BadgesIntroduction from './BadgesIntroduction';
import { GlobalContext } from '../../../store/global';
import ApplicationJson from '../../../json/application';
import BadgesList from './BadgesList';

const BadgesOverlay = class BadgesOverlay extends Component {
  static contextType = GlobalContext;

  state = {
    showBadgeList: false,
  };

  setShowBadgeList = (show) => {
    this.setState({
      showBadgeList: show,
    });
  };

  close = () => {
    const { setBadgesOverlayToggle } = this.context;
    setBadgesOverlayToggle(false);
  };

  showHelpfulHint = () => {
    const { setBadgesOverlayToggle } = this.context;
    setBadgesOverlayToggle(true);
    this.setShowBadgeList(false);
  }

  closeBadgeIntroduction = () => {
    this.setShowBadgeList(true);
  }

  showBadgeInternalComponent = (showBadgeList) => {
    if (showBadgeList) {
      return (
        <BadgesList
          closeOverlay={this.close}
          showHelpfulHint={this.showHelpfulHint}
          badges={ApplicationJson.badges}
          badgeTrailDetails={ApplicationJson.screens.badges}
        />
      );
    }
    return (
      <BadgesIntroduction
        closeOverlay={this.closeBadgeIntroduction}
        setShowBadgeContent={this.setShowBadgeList}
        badgeTrailDetails={ApplicationJson.screens.badges}
      />
    );
  };

  render() {
    const { badgesOverlay } = this.context;
    const { showBadgeList } = this.state;
    return (
      <Modal isOpen={badgesOverlay} className="Modal">
        { this.showBadgeInternalComponent(showBadgeList) }
      </Modal>
    );
  }
};

export default BadgesOverlay;
