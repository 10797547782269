import L1 from './locations/location_1';
import L2 from './locations/location_2';
import L3 from './locations/location_3';
import L4 from './locations/location_4';
import L5 from './locations/location_5';
import L6 from './locations/location_6';
import L7 from './locations/location_7';

import F1 from './fun_facts/fun_facts_1';
import F2 from './fun_facts/fun_facts_2';
import F3 from './fun_facts/fun_facts_3';
import F4 from './fun_facts/fun_facts_4';
import F5 from './fun_facts/fun_facts_5';
import F6 from './fun_facts/fun_facts_6';
import F7 from './fun_facts/fun_facts_7';
import F8 from './fun_facts/fun_facts_8';

import Q1 from './questions/question_1';
import Q2 from './questions/question_2';
import Q3 from './questions/question_3';
import Q4 from './questions/question_4';
import Q5 from './questions/question_5';
import Q6 from './questions/question_6';
import Q7 from './questions/question_7';
import Q8 from './questions/question_8';
import Q9 from './questions/question_9';
import Q10 from './questions/question_10';
import Q11 from './questions/question_11';
import Q12 from './questions/question_12';
import Q13 from './questions/question_13';

import C1 from './challenges/challenge_1';
import C2 from './challenges/challenge_2';
import Badges from './badges';

export default {
  id: 3,
  title: 'Dormouse',
  avatar: 'dormouse-avatar.png',
  map: 'dormouse.png',
  summary_body_content: 'Explore Salters’ Garden and the Highwalks from there to the Museum of London with the Dormouse.',
  detail_body_content: 'Roam around Salters’ Garden in the company of the Dormouse. Then make your way up and along the streets in the sky to the Museum of London, passing Roman ruins on the way.\n' +
    'Tap the \'Eye\' symbol to tick off interesting things you see along the way - and win I Spy points!',
  introduction_body_content: 'Oh, hello up there! I’m the Dormouse. Shall we explore this garden and see where our nose takes us? It’s a nice little hidden spot next to The Salters’ Company and St Alphage Garden.',
  time_to_complete: 20,
  distance: 0.6,
  colors: {
    primary: '#9d2665',
    secondary: '#ff77bf',
    secondary_tint: 'rgba(255, 119, 191, 0.3)',
    black_tint: 'rgba(0, 0, 0, 0.3)',
  },
  totalQuestions: 13,
  totalPoints: 150,
  questions: {
    1: Q1,
    2: F1,
    3: Q2,
    4: F2,
    5: Q3,
    6: C1,
    7: Q4,
    8: F3,
    9: L1,
    10: L2,
    11: L3,
    12: Q5,
    13: F4,
    14: Q6,
    15: F5,
    16: Q7,
    17: L4,
    18: L5,
    19: L6,
    20: Q8,
    21: F6,
    22: L7,
    23: Q9,
    24: F7,
    25: Q10,
    26: F8,
    27: Q11,
    28: C2,
    29: Q12,
    30: Q13,
  },
  badges: Badges.badges,
};
