export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Can you spot lots of lily pads in the water by the Barbican? What type of animal likes to sit on lily pads to keep safe from predators?',
  answers: [
    'Frog',
    'Water snake',
    'Fish',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Frog',
  number: 13,
};
