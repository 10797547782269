export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Walk a bit further with the ruins on your left. Look up high – can you spot the giant Salters’ crest on the side of the building? Can you complete the Latin phrase – Sal Sapit…?',
  answers: [
    'Omnia',
    'Veni',
    'Vici',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Omnia',
  number: 6,
};
