export default {
  type: 'question',
  show_eye_spy_prompt: false,
  question: 'Out here you can see the museum’s café and restaurant. I like to eat all sorts. What word means ‘eats both meat and plants’? ‘If you’re not sure, have a guess!',
  answers: [
    'Omnivore',
    'Herbivore',
    'Carnivore',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Omnivore',
  number: 3,
};
