import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Image,
  Row,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import BodyContent from '../shared/BodyContent';

const NextLocation = (props) => {
  const { gameState, updateGameState, currentTrail } = props;

  const question = currentTrail.data.questions[gameState.currentQuestion];

  const previousQuestion = currentTrail.data.questions[gameState.currentQuestion - 1];

  const locationImage = () => {
    if (question.image.length === 0) {
      return null;
    }

    const locationIMG = () => require(`../../assets/images/locations/${question.image}`);

    return (
      <Image
        className="next-location__avatar"
        src={locationIMG()}
        fluid
        rounded
      />
    );
  };

  const advanceToNextQuestion = () => {
    const nextQuestion = gameState.currentQuestion + 1;
    updateGameState({
      currentQuestion: nextQuestion,
      attempts: 0,
      selectedAnswer: null,
      correct: null,
      answersGiven: [],
      seenFunFact: false,
      shuffled: false,
    });
  };

  const goBackToPreviousQuestion = () => {
    const latestPreviousQuestion = gameState.currentQuestion - 1;
    updateGameState({
      currentQuestion: latestPreviousQuestion,
      attempts: 0,
      selectedAnswer: null,
      correct: null,
      answersGiven: [],
      seenFunFact: false,
      shuffled: false,
    });
  };

  const renderPreviousLocationButton = () => {
    if (previousQuestion === undefined) {
      return null;
    }

    if (previousQuestion.type !== 'location') {
      return null;
    }
    return (
      <Button className="btn-previous" onClick={() => goBackToPreviousQuestion()}>Previous</Button>
    );
  };

  const introductionText = <BodyContent extendedClass="body--copy" bodyContent={question.directions} />;

  return (
    <div className="next-location">
      <Container>
        <Row className="next-location__centered">
          <Col xs={12} md={8}>
            {locationImage()}
            <div className="next-location__content">
              <h1>Next location</h1>
              <hr />
              {introductionText}
              <ButtonToolbar>
                {renderPreviousLocationButton()}
                <Button onClick={() => advanceToNextQuestion()}>Next</Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

NextLocation.propTypes = {
  gameState: PropTypes.shape({
    currentQuestion: PropTypes.number,
    attempts: PropTypes.number,
    selectedAnswer: PropTypes.any,
    answersGiven: PropTypes.arrayOf(PropTypes.string),
    correct: PropTypes.any,
    points: PropTypes.number,
  }),
  currentTrail: PropTypes.objectOf(PropTypes.any),
  updateGameState: PropTypes.func,
};

NextLocation.defaultProps = {
  gameState: {},
  currentTrail: {},
  updateGameState: () => {},
};

export default NextLocation;
