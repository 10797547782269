export default {
  type: 'question',
  number: 7,
  show_eye_spy_prompt: true,
  question: 'Can you spot a basketball court? I’m not great at jumping myself, but my friend the flea can jump very high! If a flea were the size of a human, how high could it jump?',
  answers: [
    '200 feet (impressive!)',
    '480 feet (boing!!)',
    '2 feet (like humans)',
  ],
  available_points: [10, 5, 1],
  correct_answer: '480 feet (boing!!)',
};
