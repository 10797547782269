import L1 from './locations/location_1';
import L2 from './locations/location_2';
import L3 from './locations/location_3';
import L4 from './locations/location_4';
import L5 from './locations/location_5';
import L6 from './locations/location_6';
import L7 from './locations/location_7';
import L8 from './locations/location_8';
import L9 from './locations/location_9';
import L10 from './locations/location_10';
import L11 from './locations/location_11';

import F1 from './fun_facts/fun_fact_1';
import F2 from './fun_facts/fun_fact_2';
import F3 from './fun_facts/fun_fact_3';
import F4 from './fun_facts/fun_fact_4';

import Q1 from './questions/question_1';
import Q2 from './questions/question_2';
import Q3 from './questions/question_3';
import C1 from './challenges/challenge_1';
import Q4 from './questions/question_4';
import Q5 from './questions/question_5';
import Q6 from './questions/question_6';
import C2 from './challenges/challenge_2';
import Q7 from './questions/question_7';
import Q8 from './questions/question_8';
import C3 from './challenges/challenge_3';
import Q9 from './questions/question_9';
import Q10 from './questions/question_10';
import Q11 from './questions/question_11';
import C4 from './challenges/challenge_4';
import Q12 from './questions/question_12';
import Q13 from './questions/question_13';
import Q14 from './questions/question_14';
import Badges from './badges';

export default {
  id: 2,
  title: 'Lion',
  avatar: 'lion-avatar.png',
  map: 'lion.png',
  summary_body_content: 'Join the Lion on a trail that leads from Beech Gardens to the entrance of the Museum of London.',
  detail_body_content: 'Join the Lion and prowl along the Highwalks, from Beech Gardens to the Museum of London. There are lots of things to spot, questions to answer and places to explore along the trail. Tap the \'Eye\' symbol to tick off interesting things you see along the way - and win I Spy points!',
  introduction_body_content: 'Hello young cubs! Are you ready to go on a stroll with me around the Highwalks? We’re going to start exploring at Beech Gardens and then see what we can find as we walk to the entrance of the Museum of London.',
  time_to_complete: 30,
  distance: 1.2,
  colors: {
    primary: '#713e9c',
    secondary: '#904bcb',
    secondary_tint: 'rgba(144, 75, 203, 0.3)',
    black_tint: 'rgba(0, 0, 0, 0.3)',
  },
  totalQuestions: 14,
  totalPoints: 180,
  questions: {
    1: L1, // nav
    2: Q1, // quest
    3: Q2, // que
    4: F1,
    5: C1, // cha
    6: Q3, // que
    7: F2,
    8: L2, // loc
    9: Q4,
    10: F3,
    11: Q5,
    12: C2,
    13: L3,
    14: Q6,
    15: L4,
    16: L5,
    17: Q7,
    18: L6,
    19: Q8,
    20: L7,
    21: L8,
    22: L9,
    23: C3,
    24: Q9,
    25: F4,
    26: L10,
    27: L11,
    28: Q10,
    29: Q11,
    30: C4,
    31: Q12,
    32: Q13,
    33: Q14,
  },
  badges: Badges.badges,
};
