export default {
  type: 'question',
  show_eye_spy_prompt: false,
  question: 'Let’s see if we can find something else around here. Can you tell me who wrote London: The Story of a Great City? The answer’s hiding in a picture!',
  answers: [
    'Jerry White',
    'Henry Green',
    'Clarence Brown',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Jerry White',
  number: 2,
};
