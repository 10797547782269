import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { GlobalContext } from '../../store/global';
import MenuIconEyeSpy from '../../assets/svgs/menu-icn-eye.svg';
import MenuIconLocation from '../../assets/svgs/menu-icn-pin.svg';
import MenuIconBadge from '../../assets/svgs/menu-icn-badge.svg';

const RightHandNavigationComponent = (props) => {
  const {
    setEyeSpyOverlayToggle,
    setBadgesOverlayToggle,
    setWarningOverlayToggle,
  } = useContext(GlobalContext);
  const { history, showLogo } = props;

  const navigateToTrail = () => {

    if (history.location.pathname.match('/trail/lion') !== null) {
      setWarningOverlayToggle(true);

      return;
    }
    if (history.location.pathname.match('/trail/fox') !== null) {
      setWarningOverlayToggle(true);

      return;
    }
    if (history.location.pathname.match('/trail/dormouse') !== null) {
      setWarningOverlayToggle(true);

      return;
    }

    //history.goBack();

    history.push('/select-trail');
  };
  const shouldItemHaveSelectedClass = () => {
  };

  const renderRightHandNavigation = () => {
    if (showLogo === true) {
      return null;
    }

    return (
      <Fragment>
        <ReactSVG
          src={MenuIconLocation}
          onClick={() => navigateToTrail()}
          className={shouldItemHaveSelectedClass('location')}
          beforeInjection={svg => {
            svg.classList.add('navigation__link');
            svg.classList.add('ml-3');
          }}
        />
        <ReactSVG
          src={MenuIconEyeSpy}
          className={shouldItemHaveSelectedClass('eye-spy')}
          onClick={() => setEyeSpyOverlayToggle(true)}
          beforeInjection={svg => {
            svg.classList.add('navigation__link');
            svg.classList.add('ml-3');
          }}
        />
        <ReactSVG
          src={MenuIconBadge}
          className={shouldItemHaveSelectedClass('badges')}
          onClick={() => setBadgesOverlayToggle(true)}
          beforeInjection={svg => {
            svg.classList.add('navigation__link');
            svg.classList.add('ml-3');
          }}
        />
      </Fragment>
    );
  };
  return (
    <Fragment>
      {renderRightHandNavigation()}
    </Fragment>
  );
};

RightHandNavigationComponent.propTypes = {
  showLogo: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

RightHandNavigationComponent.defaultProps = {
  history: {},
  location: {},
  showLogo: false,
};

export default withRouter(RightHandNavigationComponent);
