import React, { Fragment } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import ApplicationJson from './json/application';
import SplashScreen from './pages/SplashScreen';
import SelectTrail from './pages/SelectTrail';
import TrailDetail from './pages/TrailDetail';
import TrailIntroduction from './pages/TrailIntroduction';
import Game from './pages/Game';
import TrailResult from './pages/TrailResult';
import NextLocation from './pages/NextLocation';
import WithRouterNavigation from './components/Navigation';
import EyeSpyOverlay from './components/overlays/eyespy/EyeSpyOverlay';
import BadgesOverlay from './components/overlays/badges/BadgesOverlay';
import WithRouterWarningOverlay from './components/overlays/warning/WarningOverlay';
import AwardedBadgeOverlay from './components/overlays/awardedbadge/AwardedBadgeOverlay';
import ScrollToTop from './components/ScrollTop';

library.add(fas);

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
ReactGA.set({ anonymizeIp: true });
ReactGA.pageview('/explorers');

const App = () => (
  <Fragment>
    <WithRouterNavigation />
    <ScrollToTop>
      <Switch>
        <Route exact path="/" render={props => <SplashScreen {...props} splashScreen={ApplicationJson.screens.splash_screen} />} />
        <Route exact path="/select-trail" render={props => <SelectTrail {...props} trails={ApplicationJson.trails} />} />
        <Route exact path="/trail-detail" render={props => <TrailDetail {...props} trails={ApplicationJson.trails} />} />
        <Route exact path="/trail-introduction/:trail" render={props => <TrailIntroduction {...props} trails={ApplicationJson.trails} />} />
        <Route exact path="/trail/:trail" render={props => <Game {...props} trails={ApplicationJson.trails} />} />
        <Route exact path="/trail/:trail/result" render={props => <TrailResult {...props} trails={ApplicationJson.trails}  />} />
        <Route exact path="/trail/:trail/next-location" render={props => <NextLocation {...props} trails={ApplicationJson.trails} />} />
      </Switch>
    </ScrollToTop>
    <EyeSpyOverlay />
    <BadgesOverlay />
    <WithRouterWarningOverlay />
    <AwardedBadgeOverlay
      badges={ApplicationJson.badges}
    />
  </Fragment>
);

export default App;
