export default {
  type: 'question',
  number: 10,
  show_eye_spy_prompt: true,
  question: 'Can you see the pictures of Londoners past and present on the café window? One of them is holding a bottle – what type of bottle is it?',
  answers: [
    'Milk bottle',
    'Wine bottle',
    'Water bottle',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Milk bottle',
};
