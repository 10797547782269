export default {
  type: 'question',
  show_eye_spy_prompt: false,
  question: 'Let’s go a bit deeper into the garden. Can you tell me who the Master Salter was from 2001-2002?',
  answers: [
    'Jock Russell',
    'Phillip Remnant',
    'Michael Ayrton',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Jock Russell',
  number: 3,
};
