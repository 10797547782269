export default {
  type: 'question',
  number: 2,
  show_eye_spy_prompt: false,
  question: 'Can you find Ben Jonson House? Count how many levels high it is.',
  answers: [
    '5',
    '7',
    '6',
  ],
  available_points: [10, 5, 1],
  correct_answer: '6',
};
