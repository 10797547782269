import React from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
import LeftHandNavigationComponent from './navigation/LeftHandNavigationComponent';
import RightHandNavigationComponent from './navigation/RightHandNavigationComponent';

export const Navigation = (props) => {
  const { location } = props;

  let showLogo = false;

  if (location.pathname === '/') {
    showLogo = true;
  }

  if (location.pathname === '/') {
    showLogo = true;
  }

  return (
    <Navbar className="navigation">
      <Nav className="mr-auto">
        <LeftHandNavigationComponent showLogo={showLogo} />
      </Nav>
      <Nav>
        <RightHandNavigationComponent showLogo={showLogo} />
      </Nav>
    </Navbar>
  );
};

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

Navigation.defaultProps = {
  location: {},
};

export default withRouter(Navigation);
