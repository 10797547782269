import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Container,
  Image,
  Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../store/global';
import ReactGA from "react-ga";

const TrailResult = (props) => {
  const {
    selected,
    getSelectedTrail,
    setSelected,
    pointsEarned,
    commitAchievedPoints,
    awardBronzeMedal,
    awardSilverMedal,
    awardGoldMedal,
    currentAwardedBadge,
    setCurrentAwardedBadge,
    setAwardedBadgeOverlayToggle,
    myBadges,
  } = useContext(GlobalContext);
  const { trails, match, history } = props;
  const completedTrail = selected !== null ? trails[getSelectedTrail].data : {};

  const trailKey = match.params.trail;
  const myPoints = pointsEarned[trailKey] !== undefined
    ? pointsEarned[trailKey]
    : 0;

  const getTrailImagePath = () => {
    if (selected !== null) {
      return require(`../assets/images/avatars/${completedTrail.avatar}`);
    }

    return null;
  };

  const pointsAchieved = () => {
    if (myPoints.current < myPoints.achieved && myPoints.current !== 0) {
      return myPoints.current;
    }

    return myPoints.achieved;
  };

  const renderNextButton = () => {
    if (completedTrail.next_trail !== undefined && completedTrail.next_trail !== null) {
      return <Link to={`/trail/${trailKey}/next-location`} className="btn btn-primary btn-block btn-lg btn-next-location" role="button">Next Location</Link>;
    }

    return <Link to="/select-trail" className="btn btn-primary btn-block btn-lg btn-next-location" role="button">Next</Link>;
  };

  useEffect(() => {
    ReactGA.event({
      category: 'Trail Actions',
      action: 'Ended Trail',
      label: completedTrail.title
    })
  },[]);

  useEffect(() => {
    if (myPoints.achieved === undefined) {
      history.push('/select-trail');
    }
  }, [history, myPoints]);

  useEffect(() => {
    setSelected(trails.findIndex(obj => obj.key === trailKey) + 1);

    if (myPoints.achieved === 0) {
      commitAchievedPoints(trailKey);
    }

    if (myPoints.current > myPoints.achieved) {
      commitAchievedPoints(trailKey);
    }
  }, [commitAchievedPoints, trailKey, myPoints, trails, setSelected]);

  useEffect(() => {
    localStorage.setItem('points-earned', JSON.stringify(pointsEarned));

    if (trails[getSelectedTrail] || trails[getSelectedTrail] !== undefined) {
      const currentTrailKey = match.params.trail;
      const currentTrailPoints = pointsEarned[currentTrailKey];
      const currentTrailBadges = trails[getSelectedTrail].data.badges;

      if ((currentTrailPoints.current >= currentTrailBadges.bronze.score
          && currentTrailPoints.current < currentTrailBadges.silver.score)
          && currentAwardedBadge === null) {
        awardBronzeMedal(currentTrailKey);
        setCurrentAwardedBadge('bronze');
      }

      if ((currentTrailPoints.current >= currentTrailBadges.silver.score
            && currentTrailPoints.current < currentTrailBadges.gold.score)
        && currentAwardedBadge === null) {
        awardSilverMedal(currentTrailKey);
        setCurrentAwardedBadge('silver');
      }

      if (currentTrailPoints.current >= currentTrailBadges.gold.score
        && currentAwardedBadge === null) {
        awardGoldMedal(currentTrailKey);
        setCurrentAwardedBadge('gold');
      }

      if (currentAwardedBadge === null) {
        setAwardedBadgeOverlayToggle(true);
      }

      localStorage.setItem('my-badges', JSON.stringify(myBadges));
    }
  });

  const renderResultText = () => {
    const completedTrailColors = completedTrail.colors !== undefined ? completedTrail.colors : {};

    const maximumPointsAvailable = completedTrail.totalPoints;

    return (
      <div
        className="trail-result"
        style={{ backgroundColor: completedTrailColors.primary }}
      >
        <div
          className="trail-result__wrapper"
          style={{
            background: `linear-gradient(#ffffff 32%, #fff 0%, ${completedTrailColors.primary} 25%)`,
          }}
        >
          <Container>
            <Row className="trail-result__centered">
              <Col xs={12} md={8}>
                <Image
                  className="trail-result__avatar"
                  src={getTrailImagePath()}
                  style={{ borderColor: completedTrailColors.primary }}
                  fluid
                  rounded
                />
                <div className="trail-result__content">
                  <h1>{completedTrail.title}</h1>
                  <hr />
                  <h4>Congratulations!</h4>
                  <p>You have successfully scored:</p>
                  <div className="trail-result__points">
                    <h5>Points:</h5>
                    <h5>{`${pointsAchieved()}/${maximumPointsAvailable}`}</h5>
                  </div>
                  <p className="trail-result__lowercase">Now click the ‘Eye’ symbol to tick off any interesting things you might have spotted on your journey!</p>
                  <p className="trail-result__lowercase">For more stories about the city told through the eyes of animals, visit Beasts of London at the Museum of London</p>
                  <a href="https://www.museumoflondon.org.uk/museum-london/whats-on/exhibitions/beastsoflondon" className="btn btn-secondary btn-block btn-lg">Find out more</a>
                  { renderNextButton() }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  };

  return renderResultText();
};

TrailResult.propTypes = {
  trails: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.shape({
    params: PropTypes.shape({
      trail: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }),
};

TrailResult.defaultProps = {
  trails: {},
  match: {},
  history: {},
};

export default TrailResult;
