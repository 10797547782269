import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const GlobalContext = createContext({});

const GlobalProvider = (props) => {
  const [selected, setSelected] = useState(null);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [eyeSpyOverlay, setEyeSpyOverlayToggle] = useState(false);
  const [badgesOverlay, setBadgesOverlayToggle] = useState(false);
  const [warningOverlay, setWarningOverlayToggle] = useState(false);
  const [funFactOverlay, setFunFactOverlayToggle] = useState(false);
  const [showAwardedBadgeOnNextScreen, setShowAwardedBadgeOnNextScreen] = useState(false);
  const [awardedBadgeOverlay, setAwardedBadgeOverlayToggle] = useState(false);
  const [foundEyeSpy, setFoundEyeSpy] = useState(JSON.parse(localStorage.getItem('found-eye-spies')) || []);
  const [notFoundEyeSpy, removeFoundEyeSpy] = useState(localStorage.removeItem('found-eye-spies')) || [];
  const [myBadges, setMyBadges] = useState(JSON.parse(localStorage.getItem('my-badges')) || {});
  const [pointsEarned, setPointsEarned] = useState(JSON.parse(localStorage.getItem('points-earned')) || {});
  const [currentFunFact, setCurrentFunFact] = useState({});
  const [currentAwardedBadge, setCurrentAwardedBadge] = useState(null);

  const getSelectedTrail = selected !== null ? selected - 1 : selected;

  const setInitialBadgeState = (key) => {
    const initialBadgeState = {
      ...myBadges,
      [key]: {
        bronze: false,
        silver: false,
        gold: false,
      },
    };

    setMyBadges(initialBadgeState);
  };

  const awardBronzeMedal = (key) => {
    const badgeObj = {
      ...myBadges,
      [key]: {
        ...myBadges[key],
        bronze: true,
        silver: false,
        gold: false,
      },
    };

    setMyBadges(badgeObj);
  };

  const awardSilverMedal = (key) => {
    const badgeObj = {
      ...myBadges,
      [key]: {
        ...myBadges[key],
        bronze: true,
        silver: true,
        gold: false,
      },
    };

    setMyBadges(badgeObj);
  };

  const awardGoldMedal = (key) => {
    const badgeObj = {
      ...myBadges,
      [key]: {
        ...myBadges[key],
        bronze: true,
        silver: true,
        gold: true,
      },
    };

    setMyBadges(badgeObj);
  };

  const setInitialPointsEarnedState = (key) => {
    const initialPointsEarnedState = {
      ...pointsEarned,
      [key]: {
        current: 0,
        achieved: 0,
      },
    };

    setPointsEarned(initialPointsEarnedState);
  };

  const awardPoints = (key, points) => {
    const newPointTotal = points + pointsEarned[key].current;
    const pointsObj = {
      ...pointsEarned,
      [key]: {
        ...pointsEarned[key],
        current: newPointTotal,
      },
    };

    setPointsEarned(pointsObj);
  };

  const commitAchievedPoints = (key) => {
    const achievedPoints = pointsEarned[key].current;
    const pointsObj = {
      ...pointsEarned,
      [key]: {
        achieved: achievedPoints,
        current: 0,
      },
    };

    setPointsEarned(pointsObj);
  };

  const clearCurrentPointsEarned = (key) => {
    const pointsObj = {
      ...pointsEarned,
      [key]: {
        ...pointsEarned[key],
        current: 0,
      },
    };

    setPointsEarned(pointsObj);
  };

  return (
    <GlobalContext.Provider
      value={{
        selected,
        setSelected,
        selectedBadge,
        setSelectedBadge,
        getSelectedTrail,
        eyeSpyOverlay,
        setEyeSpyOverlayToggle,
        badgesOverlay,
        setBadgesOverlayToggle,
        foundEyeSpy,
        setFoundEyeSpy,
        notFoundEyeSpy,
        removeFoundEyeSpy,
        myBadges,
        setMyBadges,
        warningOverlay,
        setWarningOverlayToggle,
        funFactOverlay,
        setFunFactOverlayToggle,
        awardedBadgeOverlay,
        setAwardedBadgeOverlayToggle,
        setInitialBadgeState,
        awardBronzeMedal,
        awardSilverMedal,
        awardGoldMedal,
        pointsEarned,
        setPointsEarned,
        setInitialPointsEarnedState,
        awardPoints,
        commitAchievedPoints,
        clearCurrentPointsEarned,
        currentFunFact,
        setCurrentFunFact,
        currentAwardedBadge,
        setCurrentAwardedBadge,
        showAwardedBadgeOnNextScreen,
        setShowAwardedBadgeOnNextScreen,
      }}
      {...props}
    />
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};

GlobalProvider.defaultProps = {
  children: {},
};

export { GlobalContext, GlobalProvider };
