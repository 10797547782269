import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import CloseIcon from '../../../assets/svgs/icn-cross.svg';
import BodyContent from '../../shared/BodyContent';

const EyeSpyIntroduction = (props) => {
  const { closeOverlay, eyeSpies } = props;

  const introOverlayDetails = eyeSpies.intro_overlay;

  const introductionText = <BodyContent extendedClass="body--copy" bodyContent={introOverlayDetails.body_content} />;

  const eyeSpyIntroductionImage = () => {
    const badgeIcon = () => require('../../../assets/svgs/icn-eyespy.svg').toString();
    return (
      <ReactSVG
        src={badgeIcon()}
        onClick={closeOverlay}
        className="badges-introduction__image overlay__image"
      />
    );
  };

  return (
    <Container className="eye-spy-introduction overlay overlay--eye-spy">
      <Row className="actions">
        <Col className="text-right">
          <ReactSVG
            src={CloseIcon}
            onClick={closeOverlay}
            className="icon-close"
          />
        </Col>
      </Row>
      <Row className="content">
        <Col className="xs-12">
          {eyeSpyIntroductionImage()}
          <h1 className="header--two">{introOverlayDetails.title}</h1>
          <hr />
          <h3 className="header--three">{introOverlayDetails.subtitle}</h3>
          {introductionText}
        </Col>
      </Row>
    </Container>
  );
};

EyeSpyIntroduction.propTypes = {
  closeOverlay: PropTypes.func,
  setShowContent: PropTypes.func,
  eyeSpies: PropTypes.shape(),
};

EyeSpyIntroduction.defaultProps = {
  closeOverlay: () => {},
  setShowContent: () => {},
  eyeSpies: [],
};

export default EyeSpyIntroduction;
