export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Can you see St Giles Cripplegate church? What shape decorates the very top?',
  answers: [
    'An arrow',
    'A chicken',
    'A person',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'An arrow',
  number: 10,
};
