export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'What amazing ruins on the left! This city certainly has changed over the years. Can you find out when Romans first built a fort in this area?',
  answers: [
    'AD100-120',
    'AD10-20',
    'AD500',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'AD100-120',
  number: 7,
};
