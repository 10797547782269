export default {
  type: 'question',
  number: 14,
  show_eye_spy_prompt: false,
  question: 'Ah ha! Now there is a word I recognise from Africa. Can you have a look around the museum entrance and tell me what Akwaaba means?',
  answers: [
    'London',
    'Welcome',
    'Museum',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Welcome',
};
