export default {
  type: 'question',
  show_eye_spy_prompt: false,
  question: 'When was the previous building on this site destroyed?',
  answers: [
    'May 1941',
    'May 2001',
    'May 1641',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'May 1941',
  number: 4,
};
