export default {
  key: 'fox',
  image: 'images/avatars/fox-avatar.png',
  badges: {
    bronze: {
      image: 'svgs/fox-print-03-large.svg',
      score: 54, //30%
    },
    silver: {
      image: 'svgs/fox-print-03-large.svg',
      score: 108,
    },
    gold: {
      image: 'svgs/fox-print-03-large.svg',
      score: 180,//100%
    },
  },
};
