import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../store/global';

const funFactImage = () => {
  const image = () => require('../../assets/svgs/icn-funfact.svg').toString();
  return (
    <ReactSVG
      src={image()}
    />
  );
};

const FunFact = class FunFactOverlay extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      showLocation: false,
      seen: false,
    };

    this.fun_fact = props.currentTrail.data.questions[props.gameState.currentQuestion];
  }

  advanceToNextQuestion = () => {
    const nextQuestion = this.props.gameState.currentQuestion + 1;
    this.props.updateGameState({
      currentQuestion: nextQuestion,
      attempts: 0,
      selectedAnswer: null,
      correct: null,
      answersGiven: [],
      seenFunFact: false,
      shuffled: false,
    });
  };

  showCloseButton = () => {
    const { currentTrail } = this.props;
    const { gameState } = this.props;
    const questionCount = Object.keys(currentTrail.data.questions).length;

    if (gameState.currentQuestion === questionCount) {
      return null;
    }

    return (
      <FontAwesomeIcon
        onClick={() => this.advanceToNextQuestion()}
        icon="times"
        color="white"
        size="2x"
      />
    );
  };

  showResultsButton = () => {
    const { currentTrail } = this.props;
    const questionCount = Object.keys(currentTrail.data.questions).length;
    const { gameState } = this.props;

    if (gameState.currentQuestion === questionCount) {
      return (
        <div className="overlay__footer multiple-choice-question__footer">
          <Link to={{ pathname: `${currentTrail.key}/result` }} className="btn btn-primary" role="button">Continue</Link>
        </div>
      );
    }
    return (
      <div className="overlay__footer multiple-choice-question__footer">
        <button className="btn btn-primary" onClick={() => this.advanceToNextQuestion()}>Interesting!</button>
      </div>
    );
  };

  render() {
    return (
      <Container className="fun-fact-overlay">
        <Row>
          <Col xs={12}>
            <div className="fun-fact-overlay__space-between">
              <div className="fun-fact-overlay__navigation">
                {this.showCloseButton()}
              </div>
              <div className="fun-fact-overlay__content">
                <div className="fun-fact-overlay__icon">
                  {funFactImage()}
                </div>
                <h1>Fun Fact</h1>
                <hr />
                <h4>Did you know?</h4>
                <p>{this.fun_fact.fun_fact}</p>
                {this.showResultsButton()}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

FunFact.propTypes = {
  gameState: PropTypes.shape({
    currentQuestion: PropTypes.number,
    attempts: PropTypes.number,
    selectedAnswer: PropTypes.any,
    answersGiven: PropTypes.arrayOf(PropTypes.string),
    correct: PropTypes.any,
    points: PropTypes.number,
  }),
  currentTrail: PropTypes.objectOf(PropTypes.any),
  updateGameState: PropTypes.func,
};

FunFact.defaultProps = {
  gameState: {},
  currentTrail: {},
  updateGameState: () => {},
};
export default FunFact;
