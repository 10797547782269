import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { GlobalContext } from '../store/global';

const TrailIntroduction = (props) => {
  const { selected, setSelected, getSelectedTrail } = useContext(GlobalContext);
  const { trails, match } = props;

  useEffect(() => {
    setSelected(trails.findIndex(obj => obj.key === match.params.trail) + 1);
  }, [setSelected, match, trails]);

  const selectedTrail = selected !== null ? trails[getSelectedTrail] : null;

  const getTrailImagePath = () => {
    if (selected !== null) {
      return require(`../assets/images/${selectedTrail.key}-full.png`);
    }

    return null;
  };

  return (
    <div
      className="trail-introduction"
      style={{ backgroundImage: `url(${getTrailImagePath()})` }}
    >
      <Container>
        <Row>
          <Col>
            <div className="trail-introduction__wrapper">
              <div
                className="trail-introduction__content"
                style={{ backgroundColor: `${selected !== null ? selectedTrail.data.colors.primary : ''}` }}
              >
                <h1>{selected !== null ? selectedTrail.data.title : ''}</h1>
                <hr />
                <p>{selected !== null ? selectedTrail.data.introduction_body_content : ''}</p>
                <Link to={`/trail/${selected !== null ? selectedTrail.key : ''}`} className="btn btn-primary" role="button">Next</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

TrailIntroduction.propTypes = {
  trails: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.shape({
    params: PropTypes.shape({
      trail: PropTypes.string,
    }),
  }),
};

TrailIntroduction.defaultProps = {
  trails: {},
  match: {},
};

export default TrailIntroduction;
