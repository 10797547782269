export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'We dormice were a favourite meal of the Romans. Eek! They also liked their wine. When was Davy’s wine merchants established?',
  answers: [
    '1870',
    '1978',
    '2018',
  ],
  available_points: [10, 5, 1],
  correct_answer: '1870',
  number: 8,
};
