import React, { useContext } from 'react';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, ButtonToolbar, Button } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import { Col, Row } from 'react-bootstrap';
import { GlobalContext } from '../../../store/global';
import CloseIcon from '../../../assets/svgs/icn-cross.svg';
import BodyContent from '../../shared/BodyContent';
import ApplicationJson from '../../../json/application';

export const WarningOverlay = (props) => {
  const {
    setSelected,
    warningOverlay,
    setWarningOverlayToggle,
  } = useContext(GlobalContext);
  const { history } = props;

  const warningScreen = ApplicationJson.screens.warning;

  const close = () => {
    setWarningOverlayToggle(false);
  };

  const bodyText = <BodyContent extendedClass="body--copy" bodyContent={warningScreen.body_content} />;


  const leave = () => {
    history.push('/select-trail');

    setSelected(null);
    setWarningOverlayToggle(false);
  };

  const warningImage = () => {
    const badgeIcon = () => {
      return require(`../../../assets/svgs/icn-areyousure.svg`).toString();
    };
    return (
      <ReactSVG
        src={badgeIcon()}
        onClick={() => close()}
        className="badges-introduction__image overlay__image"
      />
    );
  };
  const renderWarningContent = () => (
    <Container className="overlay overlay--warning">
      <Row className="actions">
        <Col className="text-right">
          <ReactSVG
            src={CloseIcon}
            onClick={() => close()}
            className="icon-close"
          />
        </Col>
      </Row>
      <Row className="content">
        <Col className="xs-12">
          {warningImage()}
          <h1 className="header--one">Are you sure?</h1>
          <hr />
          <h3 className="header--three">Just checking</h3>
          {bodyText}

          <ButtonToolbar>
            <Button onClick={() => leave()}>Yes</Button>
            <Button variant="outline-secondary btn-outline-secondary__light-background" onClick={() => close()}>No</Button>
          </ButtonToolbar>
        </Col>
      </Row>

    </Container>
  );

  return <Modal isOpen={warningOverlay} className="Modal">{renderWarningContent()}</Modal>;
};

WarningOverlay.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }),
};

WarningOverlay.defaultProps = {
  history: {},
};

export default withRouter(WarningOverlay);
