export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'I hope you have good eyesight. Let’s look down into the garden for the answer to this question. What did Milo the Cretonian do with the Ox he slew?',
  answers: [
    'Ate it in one meal',
    'Gave it to the poor to eat',
    'Threw it across the Thames',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Ate it in one meal',
  number: 5,
};
