import React, { Fragment, Component } from 'react';
import { Button, Image, ButtonToolbar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../store/global';

const MultipleChoiceQuestion = class MultipleChoiceQuestion extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.question = props.currentTrail.data.questions[props.gameState.currentQuestion];

    this.answers = this.currentQuestionAnswers(
      props.currentTrail.data.questions[props.gameState.currentQuestion],
    );

    this.previousQuestion = props.currentTrail.data.questions[props.gameState.currentQuestion - 1];

    this.answerSelected = false;
  }

  componentWillReceiveProps(nextProps) {
    const { gameState, currentTrail } = nextProps;
    this.question = currentTrail.data.questions[gameState.currentQuestion];

    this.previousQuestion = currentTrail.data.questions[gameState.currentQuestion - 1];

    if (gameState.attempts === 0
      && gameState.currentQuestion > 0
      && gameState.shuffled === false) {
      this.answers = this.currentQuestionAnswers(
        currentTrail.data.questions[gameState.currentQuestion],
      );
    }

    this.answerSelected = false;
  }

  getTrailAvatarPath = () => {
    const { selected } = this.context;
    const { currentTrail } = this.props;

    if (selected !== null) {
      return require(`../../assets/images/avatars/${currentTrail.data.avatar}`);
    }

    return null;
  };

  advanceToNextQuestion = () => {
    const { gameState, updateGameState } = this.props;
    const nextQuestion = gameState.currentQuestion + 1;

    if (gameState.correct !== null && gameState.correct !== false) {
      updateGameState({
        currentQuestion: nextQuestion,
        attempts: 0,
        selectedAnswer: null,
        correct: null,
        answersGiven: [],
        seenFunFact: false,
        shuffled: false,
        showAwardedBadgeOnNextScreen: true,
      });
    }
  };

  goBackToPreviousQuestion = () => {
    const { gameState, updateGameState } = this.props;

    const previousQuestion = gameState.currentQuestion - 1;
    updateGameState({
      currentQuestion: previousQuestion,
      attempts: 0,
      selectedAnswer: null,
      correct: null,
      answersGiven: [],
      seenFunFact: false,
      shuffled: false,
    });
  }

  markAsCurrentSelectedAnswer = (answer) => {
    const { gameState, updateGameState } = this.props;
    if (gameState.correct !== true && gameState.selectedAnswer !== answer) {
      updateGameState({ selectedAnswer: answer, shuffled: true });
      this.answerSelected = true;
    }
  };

  checkAnswer = (answer) => {
    const { awardPoints } = this.context;
    const { gameState, updateGameState, currentTrail } = this.props;

    if (answer === null) {
      return null;
    }

    if (gameState.answersGiven.includes(answer)) {
      return null;
    }

    if (gameState.correct !== true) {
      if (answer === this.question.correct_answer) {
        const trailKey = currentTrail.key;

        updateGameState({
          correct: true,
          selectedAnswer: answer,
          attempts: gameState.attempts + 1,
          answersGiven: [...gameState.answersGiven, answer],
          showAwardedBadgeOnNextScreen: true,
        });

        return awardPoints(
          trailKey,
          this.question.available_points[gameState.attempts],
        );
      }
    }

    return updateGameState({
      correct: false,
      selectedAnswer: answer,
      attempts: gameState.attempts + 1,
      answersGiven: [...gameState.answersGiven, answer],
    });
  };

  currentQuestionAnswers = (currentQuestion) => {
    const { gameState } = this.props;

    if (gameState.selectedAnswer === null) {
      return currentQuestion
        .answers
        .map(a => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map(a => a.value);
    }
    return currentQuestion.answers;
  }

  render() {
    const { selected, pointsEarned } = this.context;
    const { gameState, currentTrail } = this.props;

    const renderQuestionHeader = () => {
      const trailKey = currentTrail.key;
      const points = pointsEarned[trailKey];

      return (
        <div
          className="multiple-choice-question__header"
          style={{ backgroundColor: `${currentTrail.data.colors.primary}` }}
        >
          <div
            className={`multiple-choice-question__avatar  ${currentTrail.data.title.toLowerCase()}`}
            style={{

              borderColor: `${currentTrail.data.colors.primary}`,
            }}
          >
            <Image src={this.getTrailAvatarPath()} fluid />
          </div>
          <div className="multiple-choice-question__points">
            <hr />
            <p><strong>{`Your Points: ${points !== undefined && !Number.isNaN(points.current) ? points.current : 0}`}</strong></p>
          </div>
        </div>
      );
    };

    const renderQuestionInformation = () => {
      const questionCount = currentTrail.data.totalQuestions;

      return (
        <div className="multiple-choice-question__information">
          <h2>{currentTrail.data.title}</h2>
          <hr style={{ borderColor: `${currentTrail.data.colors.secondary_tint}` }} />
          <div className="multiple-choice-question__information-details">
            <strong>{`Question ${this.question.number}:`}</strong>
            <strong>{`${this.question.number}/${questionCount}`}</strong>
          </div>
          <p
            className="multiple-choice-question__question"
            style={{ backgroundColor: `${currentTrail.data.colors.secondary_tint}` }}
          >
            {this.question.question}
          </p>
        </div>
      );
    };

    const renderAnswers = () => (
      <div className={`multiple-choice-question__answers ${currentTrail.data.title.toLowerCase()}`}>
        {this.answers.map((answer) => {
          // IF the answer we're looping through has been included and is incorrect show this state
          let extraClasses = '';

          let style = {
            borderColor: currentTrail.data.colors.secondary_tint,
          };

          if (gameState.answersGiven.includes(answer) && answer !== this.question.correct_answer) {
            extraClasses += 'multiple-choice-question__answer--no';
            style = {
              backgroundColor: currentTrail.data.colors.primary,
              borderColor: currentTrail.data.colors.primary,
            };
          }

          if (answer === gameState.selectedAnswer) {
            extraClasses += ' multiple-choice-question__answer--selected ';
          }

          if (answer === this.question.correct_answer && gameState.correct === true) {
            extraClasses += 'multiple-choice-question__answer--yes';
            style = {
              backgroundColor: currentTrail.data.colors.primary,
              borderColor: currentTrail.data.colors.primary,
            };
          }

          if (gameState.answersGiven.includes(answer) && answer !== this.question.correct_answer) {
            return (
              <div
                role="button"
                className="multiple-choice-question__answer multiple-choice-question__answer--no"
                style={{
                  backgroundColor: `${currentTrail.data.colors.primary}`,
                  borderColor: `${currentTrail.data.colors.primary}`,
                }}
                key={answer}
              >
                Oops, incorrect! Try again
                <div className="multiple-choice-question__incorrect">
                  <FontAwesomeIcon icon="times" color="black" />
                </div>
              </div>
            );
          }

          if (answer === this.question.correct_answer && gameState.correct === true) {
            const pluralise = this.question.available_points[gameState.attempts - 1] === 1
              ? 'Point'
              : 'Points';

            return (
              <div
                role="button"
                className="multiple-choice-question__answer multiple-choice-question__answer--yes"
                style={{
                  backgroundColor: `${currentTrail.data.colors.primary}`,
                  borderColor: `${currentTrail.data.colors.primary}`,
                }}
                key={answer}
                onClick={() => this.checkAnswer(answer)}
              >
                {answer === this.question.correct_answer && gameState.correct === true
                  ? `Correct! ${this.question.available_points[gameState.attempts - 1]} ${pluralise}`
                  : null}
                <div className="multiple-choice-question__correct">
                  <FontAwesomeIcon icon="check" color="black" />
                </div>
              </div>
            );
          }

          return (
            <div
              role="button"
              className={`multiple-choice-question__answer ${extraClasses}`}
              style={style}
              key={answer}
              onClick={() => this.markAsCurrentSelectedAnswer(answer)}
            >
              {answer}
              <div
                className="multiple-choice-question__correct"
                style={{ backgroundColor: `${currentTrail.data.colors.secondary_tint}` }}
              />
            </div>
          );
        })}
      </div>
    );

    const renderPreviousLocationButton = () => {
      if (this.previousQuestion === undefined) {
        return null;
      }

      if (gameState.selectedAnswer !== null) {
        return null;
      }

      if (this.previousQuestion.type !== 'location') {
        return null;
      }
      return (
        <Button className="btn-previous" onClick={() => this.goBackToPreviousQuestion()}>Previous</Button>
      );
    };

    /*
     * This Renders the question foot which is either the next button
     * or continue to results
     */

    const renderQuestionFooter = () => {
      const trailKey = selected !== null ? currentTrail.key : '';
      const questionCount = selected !== null
        ? Object.keys(currentTrail.data.questions).length
        : '';

      if (gameState.currentQuestion === questionCount && gameState.correct === true) {
        return (
          <div className="multiple-choice-question__footer">
            <Link to={{ pathname: `${trailKey}/result` }} className="btn btn-primary" role="button">Continue</Link>
          </div>
        );
      }

      if (gameState.correct === true) {
        return (
          <div className={`multiple-choice-question__footer ${gameState.selectedAnswer !== null ? 'answer-selected' : ''}`}>
            <ButtonToolbar>
              {renderPreviousLocationButton()}
              <Button variant="secondary" onClick={() => this.advanceToNextQuestion()}>Next</Button>
            </ButtonToolbar>
          </div>
        );
      }

      return (
        <div className={`multiple-choice-question__footer ${gameState.selectedAnswer !== null ? 'answer-selected' : ''}`}>
          <ButtonToolbar>
            {renderPreviousLocationButton()}
            <Button variant="secondary" onClick={() => this.checkAnswer(gameState.selectedAnswer)}>Ok?</Button>
          </ButtonToolbar>
        </div>
      );
    };

    const renderQuestion = () => (
      <Fragment>
        {renderQuestionHeader()}
        {renderQuestionInformation()}
        {renderAnswers()}
        {renderQuestionFooter()}
      </Fragment>
    );

    return renderQuestion();
  }
};

MultipleChoiceQuestion.propTypes = {
  gameState: PropTypes.shape({
    currentQuestion: PropTypes.number,
    attempts: PropTypes.number,
    selectedAnswer: PropTypes.any,
    answersGiven: PropTypes.arrayOf(PropTypes.string),
    correct: PropTypes.any,
    points: PropTypes.number,
  }),
  currentTrail: PropTypes.objectOf(PropTypes.any),
  updateGameState: PropTypes.func,
};

MultipleChoiceQuestion.defaultProps = {
  gameState: {},
  currentTrail: {},
  updateGameState: () => {},
};

export default MultipleChoiceQuestion;
