export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'As you keep walking towards the museum entrance, look for the giant City of London crest with two beautiful dragons either side. Can you complete the Latin motto – Domine Dirige…',
  answers: [
    'Bos',
    'Dos',
    'Nos',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Nos',
  number: 9,
};
