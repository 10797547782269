export default {
  type: 'question',
  number: 9,
  show_eye_spy_prompt: true,
  question: 'Spot the beautiful ‘Ironmongers’ Hall. Above the gate is its crest – can you spot some scaly characters on it?',
  answers: [
    '7',
    '3',
    '4',
  ],
  available_points: [10, 5, 1],
  correct_answer: '4',
};
