import React from 'react';
import PropTypes from 'prop-types';
import { GlobalProvider } from './global';

const ProviderComposer = ({ contexts, children }) => contexts.reduceRight(
  (kids, parent) => React.cloneElement(parent, {
    children: kids,
  }),
  children,
);

const ContextProvider = ({ children }) => (
  <ProviderComposer contexts={[<GlobalProvider />]}>{children}</ProviderComposer>
);

ContextProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};

ContextProvider.defaultProps = {
  children: {},
};

export default ContextProvider;
