export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Okay, this is my last question. It’s about food again, yum. What year was the sandwich made popular by the Earl of Sandwich?',
  answers: [
    '1762',
    '1662',
    '1862',
  ],
  available_points: [10, 5, 1],
  correct_answer: '1762',
  number: 13,
};
