export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Wait! Before you leave the covered area, can you find the sculpture of two people dancing? What year is carved into it?',
  answers: [
    '1992',
    '2012',
    '2002',
  ],
  available_points: [10, 5, 1],
  correct_answer: '1992',
  number: 9,
};
