import React, { useContext } from 'react';
import Modal from 'react-modal';
import { Col, Row } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../../store/global';
import CloseIcon from '../../../assets/svgs/icn-cross.svg';
import Badge from '../badges/Badge';

const AwardedBadgeOverlay = (props) => {
  const {
    awardedBadgeOverlay,
    setAwardedBadgeOverlayToggle,
    selected,
    currentAwardedBadge,
  } = useContext(GlobalContext);

  const close = () => {
    setAwardedBadgeOverlayToggle(false);
  };
  const { badges } = props;

  const currentBadge = badges[selected];

  const badgeIntroductionImage = () => {

    const badgeIcon = () => {

      return require(`../../../assets/svgs/icn-badges.svg`).toString();
    };
    return (
      <ReactSVG
        src={badgeIcon()}
        className="badges-introduction__image overlay__image"
      />
    );
  };

  if (currentBadge === null || currentBadge === undefined) {
    return null;
  }

  if (currentAwardedBadge === null || currentAwardedBadge === undefined) {
    return null;
  }

  return (
    <Modal isOpen={awardedBadgeOverlay} className="Modal">
      <Container className="overlay overlay--awarded-badge overlay--badges-list">
        <Row className="actions">
          <Col xs={6} />
          <Col xs={6}>
            <ReactSVG
              src={CloseIcon}
              onClick={close}
              className="icon-close float-right"
            />
          </Col>
        </Row>
        <Row className="content overlay--awarded-badge__content">
          <Col className="xs-10">
            {badgeIntroductionImage()}
            <h1 className="header--two">Badges</h1>
            <hr />
            <Badge badge={currentBadge} currentBadgeAwarded={currentAwardedBadge} />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

AwardedBadgeOverlay.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.object),
};

AwardedBadgeOverlay.defaultProps = {
  badges: {},
};
export default AwardedBadgeOverlay;
