import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { GlobalContext } from '../store/global';
import Menu from '../components/Menu';

const SelectTrail = (props) => {
  const { selected, setSelected, getSelectedTrail } = useContext(GlobalContext);
  const { trails } = props;

  const menu = Menu(trails);
  const selectedTrail = selected !== null ? trails[getSelectedTrail].data : trails[0].data;

  const onSelect = (key) => {
    setSelected(parseInt(key, 10));
  };

  const getTrailMapPath = () => require(`../assets/images/maps/${selectedTrail.map}`);

  useEffect(() => {
    setSelected(1);
  }, [setSelected]);

  return (
    <div className={`select-trail ${selectedTrail.title.toLowerCase()}`}>
      <div className="select-trail__select">
        <div className="content">
          <h4>Choose a Trail:</h4>
          <p>
            Select a trail by choosing a beast below.
            Your selected trail will highlight below on the map.
          </p>
          <ScrollMenu
            scrollToSelected
            data={menu}
            selected={selected !== null ? selected : undefined}
            onSelect={e => onSelect(e)}
          />
        </div>
      </div>
      <div className={`select-trail__content ${selectedTrail.title.toLowerCase()}`}>
        <div className="content">
          <h4>{selected !== null ? selectedTrail.title : ''}</h4>
          <div className="select-trail__info">
            <p>{selected !== null ? selectedTrail.summary_body_content : ''}</p>
            <Link to="/trail-detail">
              <FontAwesomeIcon icon="chevron-right" size="3x" />
            </Link>
          </div>
        </div>
      </div>
      <div
        className="select-trail__map"
      >
        <div className="content">
        <img
          className="img-fluid"
          src={getTrailMapPath()}
          alt="trail map"
          />
        </div>
      </div>
    </div>
  );
};

SelectTrail.propTypes = {
  trails: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

SelectTrail.defaultProps = {
  trails: {},
  history: {},
};

export default SelectTrail;
