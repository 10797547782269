import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Image,
  Row,
  Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../store/global';

const NextLocation = (props) => {
  const {
    selected,
    setSelected,
    getSelectedTrail,
    pointsEarned,
  } = useContext(GlobalContext);
  const { trails, match } = props;

  useEffect(() => {
    setSelected(trails.findIndex(obj => obj.key === match.params.trail) + 1);
  }, [setSelected, match, trails]);

  const nextLocation = selected !== null ? trails[getSelectedTrail].data.next_trail : null;

  if (nextLocation === undefined || nextLocation === null) {
    return null;
  }
  const nextLocationKey = (nextLocation !== null && nextLocation !== undefined) ? nextLocation.trail : '';
  const nextLocationPointsEarned = pointsEarned[nextLocationKey] !== undefined
    ? pointsEarned[nextLocationKey].achieved
    : 0;
  const fullNextLocationKey = trails.findIndex(obj => obj.key === nextLocationKey);
  const location = trails[fullNextLocationKey] !== null ? trails[fullNextLocationKey] : null;

  const getNextLocationImage = () => {
    if (nextLocation !== null && location !== null) {
      return require(`../assets/images/avatars/${location.data.avatar}`);
    }
  };

  const renderNextButton = () => {
    if (nextLocationPointsEarned > 0) {
      return (
        <Link
          to="/select-trail"
          className="btn btn-primary"
          role="button"
        >
          Next
        </Link>
      );
    }

    return (
      <Link
        to={`/trail-introduction/${nextLocationKey}`}
        className="btn btn-primary"
        role="button"
      >
        Next
      </Link>
    );
  };

  return (
    <div className="next-location">
      <Container>
        <Row className="next-location__centered">
          <Col xs={12} md={8}>
            <Image
              className="next-location__avatar"
              src={getNextLocationImage()}
              fluid
              rounded
            />
            <div className="next-location__content">
              <h1>Next location</h1>
              <hr />
              <p>{nextLocation !== null ? location.data.summary_body_content : ''}</p>
              {renderNextButton()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

NextLocation.propTypes = {
  trails: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.shape({
    params: PropTypes.shape({
      trail: PropTypes.string,
    }),
  }),
};

NextLocation.defaultProps = {
  trails: {},
  match: {},
};

export default NextLocation;
