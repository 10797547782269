import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';

const Menu = (list, selected) => list.map((props) => {
  const { data: { avatar, id, title } } = props;
  return <MenuItem avatar={avatar} key={id} currentTrail={title} selected={selected} />;
});

Menu.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.bool,
};

Menu.defaultProps = {
  list: [],
  selected: false,
};

export default Menu;
