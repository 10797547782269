import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import NextLocation from './NextLocation';
import { GlobalContext } from '../../store/global';

const ChallengeQuestion = class ChallengeQuestion extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      showLocation: false,
      seen: false,
    };

    this.challenge = props.currentTrail.data.questions[props.gameState.currentQuestion];
  }

  getTrailAvatarPath = () => {
    const { currentTrail } = this.props;

    if (currentTrail.data !== undefined) {
      return require(`../../assets/images/avatars/${currentTrail.data.avatar}`);
    }

    return null;
  };

  yes = () => {
    const { awardPoints } = this.context;
    const { currentTrail, gameState, updateGameState } = this.props;

    updateGameState({
      ...gameState,
      correct: true,
      showAwardedBadgeOnNextScreen: true,
    });

    awardPoints(currentTrail.key, this.challenge.available_points);
  };

  no = () => {
    const { gameState, updateGameState } = this.props;

    updateGameState({
      ...gameState,
      correct: false,
      showAwardedBadgeOnNextScreen: false,
    });
  };

  challengeSuccessMessage = () => {
    if (this.challenge.success_message !== undefined && this.challenge.success_message !== '') {
      return (
        <h6>{this.challenge.success_message}</h6>
      );
    }

    return (
      <h6>for completing this challenge.</h6>
    );
  }

  advanceToNextQuestion = () => {
    const { gameState, updateGameState } = this.props;
    const nextQuestion = gameState.currentQuestion + 1;

    updateGameState({
      currentQuestion: nextQuestion,
      attempts: 0,
      selectedAnswer: null,
      correct: null,
      seenFunFact: false,
      answersGiven: [],
      shuffled: false,
    });
  };

  showChallenge = () => this.setState({ showLocation: false, seen: true });

  render() {
    const { currentTrail, gameState } = this.props;
    const { showLocation } = this.state;

    const renderChallenge = () => (
      <div
        className="challenge-question"
        style={{ backgroundColor: `${currentTrail.data.colors.primary}` }}
      >
        <div className="challenge-question__header">
          <div
            className="challenge-question__avatar"
            style={{
              backgroundImage: `url(${this.getTrailAvatarPath()})`,
              borderColor: `${currentTrail.data.colors.primary}`,
            }}
          />
        </div>
        {gameState.correct === null && (
          <div className="challenge-question__content">
            <h1>Challenge</h1>
            <hr className="challenge-question__top-hr" />
            <p>{this.challenge.challenge}</p>
            <hr className="challenge-question__bottom-hr" />
            <p>Have you completed the challenge?</p>
            <Button
              className="mr-3"
              onClick={() => this.yes()}
            >
              Yes
            </Button>
            <Button
              variant="outline-secondary btn-outline-secondary__dark-background"
              onClick={() => this.no()}
            >
              No
            </Button>
          </div>
        )}
        {gameState.correct === true && (
          <div className="challenge-question__content">
            <h1>Challenge</h1>
            <hr className="challenge-question__bottom-hr" />
            <div className="challenge-question__completed">
              <h3>Well Done!</h3>
              {this.challengeSuccessMessage()}
            </div>
            <div className="challenge-question__points">
              <p>You have been awarded:</p>
              <div
                className="challenge-question__points-block"
                style={{ backgroundColor: `${currentTrail.data.colors.black_tint}` }}
              >
                <h3>10 points</h3>
              </div>
            </div>
            <hr className="challenge-question__bottom-hr" />
            <Button onClick={() => this.advanceToNextQuestion()}>Next</Button>
          </div>
        )}
        {gameState.correct === false && (
          <Fragment>
            <div className="challenge-question__content">
              <p>
                Didn’t feel like doing that one?
                Don’t worry! Try the next one to win more points.
              </p>
              <Button onClick={() => this.advanceToNextQuestion()}>Next</Button>
            </div>
          </Fragment>
        )}
      </div>
    );

    if (showLocation) {
      return (
        <NextLocation
          location={this.challenge.location}
          showQuestion={this.showChallenge}
        />
      );
    }

    return renderChallenge();
  }
};

ChallengeQuestion.propTypes = {
  gameState: PropTypes.shape({
    currentQuestion: PropTypes.number,
    attempts: PropTypes.number,
    selectedAnswer: PropTypes.any,
    answersGiven: PropTypes.arrayOf(PropTypes.string),
    correct: PropTypes.any,
    points: PropTypes.number,
  }),
  currentTrail: PropTypes.objectOf(PropTypes.any),
  updateGameState: PropTypes.func,
};

ChallengeQuestion.defaultProps = {
  gameState: {},
  currentTrail: {},
  updateGameState: () => {},
};

export default ChallengeQuestion;
