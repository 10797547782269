export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Okay, here’s another question for you. When was Horse with Two Disks made?',
  answers: [
    '2001',
    '1981',
    '2011',
  ],
  available_points: [10, 5, 1],
  correct_answer: '2001',
  number: 4,
};
