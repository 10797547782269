export default {
  type: 'question',
  number: 3,
  show_eye_spy_prompt: false,
  question: 'Look up! The three tall towers are called Cromwell, Shakespeare and Lauderdale. Shakespeare is in the middle – what is at its very bottom?',
  answers: [
    'City of London Waste & Recycling',
    'City of London Police',
    'City of London Planning Office',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'City of London Police',
};
