export default {
  key: 'dormouse',
  image: 'images/avatars/dormouse-avatar.png',
  badges: {
    bronze: {
      image: 'svgs/dormouse-print-03-large.svg',
      score: 25,
    },
    silver: {
      image: 'svgs/dormouse-print-03-large.svg',
      score: 90,
    },
    gold: {
      image: 'svgs/dormouse-print-03-large.svg',
      score: 150,
    },
  },
};
