export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Look at the pillars to the side of the bridge. Can you find out when the Lady Eleanor Holles School was founded?',
  answers: [
    '1711',
    '1811',
    '1911',
  ],
  available_points: [10, 5, 1],
  correct_answer: '1711',
  number: 11,
};
