export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'I always think those coloured vents on the right look like giant worms. Tasty! Foxes like to eat worms, believe it or not. What DO worms have?',
  answers: [
    'A brain',
    'Arms',
    'Legs',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'A brain',
  number: 8,
};
