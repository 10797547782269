export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'Look down below the Salters’ crest. This little green park used to be a church which burned down in the Great Fire of 1666. Do you know where the fire started? If not, have a guess!',
  answers: [
    'Bakery',
    'Butchers',
    'Candlestick makers',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'Bakery',
  number: 7,
};
