export default {
  type: 'question',
  show_eye_spy_prompt: true,
  question: 'At the end of the bridge, can you find out when Queen Elizabeth II and Prince Philip’s silver wedding anniversary was?',
  answers: [
    '20th November 1972',
    '6th February 1977',
    '21st April 1976',
  ],
  available_points: [10, 5, 1],
  correct_answer: '20th November 1972',
  number: 15,
};
