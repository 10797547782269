import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import ReactSVG from 'react-svg';
import { GlobalContext } from '../../../store/global';
import Badge from './Badge';

const BadgesScroller = (props) => {
  const { selectedBadge, setSelectedBadge } = useContext(GlobalContext);
  const { badges } = props;

  const onSelect = (key) => {
    //commented out for a reason as is killing the app so need to debug @TODO
    //setSelectedBadge(parseInt(key, 10));
  };

  useEffect(() => {
    setSelectedBadge(1);
  }, [setSelectedBadge]);

  const leftArrowIcon = () => {
    return require(`../../../assets/svgs/arw-left-blk.svg`).toString();
  };

  const rightArrowIcon = () => {
    return require(`../../../assets/svgs/arw-right-blk.svg`).toString();
  };

  const ArrowLeft = <ReactSVG src={leftArrowIcon()} className="arrow-left" />;
  const ArrowRight = <ReactSVG src={rightArrowIcon()}  className="arrow-right" />;

  return (
    <div>
      <Row className="justify-content-center">
        <div>
          <ScrollMenu
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            data={badges.map(badge => <Badge key={badge.key} badge={badge} />)}
            scrollToSelected={true}
            hideSingleArrow
            hideArrows
            dragging={false}
            clickWhenDrag={false}
            selected={selectedBadge !== null ? selectedBadge : undefined}
            onSelect={e => onSelect(e)}
            scrollBy={1}
            alignCenter={false}
            itemsCount={badges.length}
            wrapperClass="single-outer-wrapper"
            innerWrapperClass="single-inner-wrapper"
            menuClass="single-menu-wrapper"
            arrowClass="arrow-class"
          />
        </div>
      </Row>
    </div>
  );
};

BadgesScroller.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      data: PropTypes.object,
      image: PropTypes.string,
      icon: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

BadgesScroller.defaultProps = {
  badges: [],
};

export default BadgesScroller;
