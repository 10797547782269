import SplashScreen from './screens/splash_screen';
import Homepage from './screens/homepage';
import Warning from './screens/warning';
import EyeSpyScreen from './screens/eye_spy';
import BadgeScreen from './screens/badges';
import TrailOne from './trails/trail_1/content';
import TrailTwo from './trails/trail_2/content';
import TrailThree from './trails/trail_3/content';
import EyeSpy from './eyespy';
import Badges from './badges';

export default {
  title: 'Culture Mile Family Trail',
  screens: {
    splash_screen: SplashScreen,
    homepage: Homepage,
    eye_spy: EyeSpyScreen,
    badges: BadgeScreen,
    warning: Warning,
  },
  trails: [
    { key: 'fox', data: TrailTwo },
    { key: 'lion', data: TrailOne },
    { key: 'dormouse', data: TrailThree },
  ],
  eye_spy: EyeSpy,
  badges: Badges,
};
