import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../store/global';
import ReactGA from 'react-ga';

const TrailSummary = (props) => {
  const { selected, getSelectedTrail } = useContext(GlobalContext);
  const { trails, history } = props;

  useEffect(() => {
    if (selected === null) {
      history.push('/select-trail');
    }
  }, [selected, history]);

  const selectedTrail = selected !== null ? trails[getSelectedTrail].data : null;
  const selectedTrailKey = selected !== null ? trails[getSelectedTrail].key : null;

  const getTrailAvatarPath = () => {
    if (selected !== null) {
      return require(`../assets/images/avatars/${selectedTrail.avatar}`);
    }

    return null;
  };

  const getTrailMapPath = () => require(`../assets/images/maps/${selectedTrail.map}`);


  if (selected === null) {
    return null;
  }

  return (
    <div className={`trail-detail ${selectedTrail.title.toLowerCase()}`}>
      <div className="trail-detail__map" style={{ backgroundImage: `url(${getTrailMapPath()})` }} />
      <div
        className="trail-detail__content"
        style={{ backgroundColor: `${selectedTrail.colors.primary}` }}
      >
        <div className="trail-detail__header">
          <div className="trail-detail__image">
            <img
              src={getTrailAvatarPath()}
              style={{
                borderColor: `${selectedTrail.colors.primary}`,
              }}
              alt={selectedTrail.title}
            />
          </div>
          <h3>{selectedTrail.title}</h3>
        </div>
        <hr />
        <div className="trail-detail__details">
          <p>
            {'time: '}
            <strong>{`${selectedTrail.time_to_complete} minutes`}</strong>
          </p>
          <p>
            {'distance: '}
            <strong>{`${selectedTrail.distance} km`}</strong>
          </p>
        </div>
        <p>{selectedTrail.detail_body_content}</p>
        <Link
          to={`/trail-introduction/${selectedTrailKey}`}
          onClick={ () => { ReactGA.event({
            category: 'Trail Actions',
            action: 'Started Trail',
            label: selectedTrail.title
          }) } }
          className="btn btn-primary"
          role="button">Start now</Link>
      </div>
    </div>
  );
};

TrailSummary.propTypes = {
  trails: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }),
};

TrailSummary.defaultProps = {
  trails: {},
  history: {},
};

export default TrailSummary;
