import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import BadgesScroller from './BadgesScroller';
import CloseIcon from '../../../assets/svgs/icn-cross.svg';
import HelpIcon from '../../../assets/svgs/icn-question-white.svg';

const BadgesList = (props) => {
  const {
    closeOverlay,
    badges,
    badgeTrailDetails,
    showHelpfulHint,
  } = props;

  const badgeIntroductionImage = () => {
    if (badgeTrailDetails.image.length === 0) {
      return null;
    }

    const badgeIcon = () => {
      return require(`../../../assets/svgs/${badgeTrailDetails.image}`).toString();
    };
    return (
      <ReactSVG
        src={badgeIcon()}
        className="badges-introduction__image overlay__image"
      />
    );
  };

  return (
    <Container className="badges-list overlay overlay--badges-list">
      <Row className="actions">
        <Col xs={6}>
          <ReactSVG
            src={HelpIcon}
            onClick={showHelpfulHint}
            className="float-left"
          />
        </Col>
        <Col xs={6}>
          <ReactSVG
            src={CloseIcon}
            onClick={closeOverlay}
            className="icon-close float-right"
          />
        </Col>
      </Row>
      <Row className="content">
        <Col xs={{ offset:1, span:10 }}>
          {badgeIntroductionImage()}
          <h1 className="header--two">Badges</h1>
          <hr />
          <BadgesScroller badges={badges} />
        </Col>
      </Row>
    </Container>
  );
};

BadgesList.propTypes = {
  closeOverlay: PropTypes.func,
  showHelpfulHint: PropTypes.func,
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      data: PropTypes.object,
      image: PropTypes.string,
      icon: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  badgeTrailDetails: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    image_alt: PropTypes.string,
    body_content: PropTypes.string,
    badges: PropTypes.array,
  }),
};

BadgesList.defaultProps = {
  closeOverlay: () => {},
  showHelpfulHint: () => {},
  badges: [],
  badgeTrailDetails: {},
};

export default BadgesList;
