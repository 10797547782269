import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BodyContent from '../components/shared/BodyContent';

const SplashScreen = (props) => {
  const { splashScreen } = props;
  const badgeIntroductionText = <BodyContent extendedClass="splash-screen__body-content body--copy" bodyContent={splashScreen.body_content} />;

  return (
    <div className="splash-screen">
      <div className="splash-screen__content">
        <h6>{splashScreen.type}</h6>
        <h1>{splashScreen.title}</h1>
        <h5>{splashScreen.blurb}</h5>
        <hr />
        {badgeIntroductionText}
        <Link to="/select-trail" className="btn btn-primary btn-lg" role="button">{splashScreen.button_text}</Link>
      </div>
    </div>
  );
};

SplashScreen.propTypes = {
  splashScreen: PropTypes.shape({
    title: PropTypes.string,
    body_content: PropTypes.string,
    button_text: PropTypes.string,
  }),
};

SplashScreen.defaultProps = {
  splashScreen: {},
};

export default SplashScreen;
