export default {
  key: 'lion',
  image: 'images/avatars/lion-avatar.png',
  badges: {
    bronze: {
      image: 'svgs/lion-print-03-large.svg',
      score: 11,
    },
    silver: {
      image: 'svgs/lion-print-03-large.svg',
      score: 40,
    },
    gold: {
      image: 'svgs/lion-print-03-large.svg',
      score: 180,
    },
  },
};
