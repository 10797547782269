import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import Slider from 'react-slick';

import EyeSpyTask from './EyeSpyTask';
import CloseIcon from '../../../assets/svgs/icn-cross.svg';
import HelpIcon from '../../../assets/svgs/icn-question-white.svg';

const EyeSpy = (props) => {
  const {
    closeOverlay,
    setShowContent,
    eyeSpies,
    showHelpfulHint,
  } = props;

  const close = () => {
    setShowContent(false);
    closeOverlay();
  };

  const leftArrowIcon = () => require('../../../assets/svgs/arw-left-blk.svg').toString();

  const rightArrowIcon = () => require('../../../assets/svgs/arw-right-blk.svg').toString();

  const ArrowLeft = <ReactSVG src={leftArrowIcon()} className="arrow-left arrow-left--eye-spy" />;
  const ArrowRight = <ReactSVG src={rightArrowIcon()} className="arrow-right arrow-right--eye-spy" />;

  const eyeSpyIntroductionImage = () => {
    const badgeIcon = () => require('../../../assets/svgs/icn-eyespy.svg').toString();
    return (
      <ReactSVG
        src={badgeIcon()}
        onClick={closeOverlay}
        className="overlay__image-small"
      />
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: ArrowRight,
    prevArrow: ArrowLeft,
    centerMode: true,
  };

  return (
    <Container className="eye-spy-listings overlay overlay--eye-spies">
      <Row className="actions">
        <Col xs={6}>
          <ReactSVG
            src={HelpIcon}
            onClick={showHelpfulHint}
            className="float-left"
          />
        </Col>
        <Col xs={6}>
          <ReactSVG
            src={CloseIcon}
            onClick={close}
            className="icon-close float-right"
          />
        </Col>
      </Row>
      <Row className="row-content">
        <Col className="content">
          {eyeSpyIntroductionImage()}
          <div className="col-10 offset-1">
            <h2 className="header--two">I Spy</h2>
            <hr />
            <div className="eye-spy-listings__body-content">
              <h3 className="header--six">On the way, I’ve seen&hellip;</h3>
            </div>
            <div className="eye-spy__tasks">
              <div className="single-menu-wrapper">
                <Slider {...settings}>
                  {eyeSpies.map(task => <EyeSpyTask key={task.id} task={task} />)}
                </Slider>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

EyeSpy.propTypes = {
  closeOverlay: PropTypes.func,
  setShowContent: PropTypes.func,
  showHelpfulHint: PropTypes.func,
  eyeSpies: PropTypes.arrayOf(PropTypes.any),
};

EyeSpy.defaultProps = {
  closeOverlay: () => {},
  setShowContent: () => {},
  eyeSpies: [],
  showHelpfulHint: () => {},
};

export default EyeSpy;
