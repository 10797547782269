export default {
  type: 'question',
  number: 6,
  show_eye_spy_prompt: false,
  question: 'Walk along Seddon Highwalk. Keep looking up high and down low, everyone. What is at the bottom of Lauderdale Tower?',
  answers: [
    'A hairdresser',
    'A shoe shop',
    'A clothes shop',
  ],
  available_points: [10, 5, 1],
  correct_answer: 'A hairdresser',
};
