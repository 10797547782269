import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalContext } from '../../../store/global';
import badges from '../../../json/badges';

const EyeSpyTask = (props) => {
  const {
    foundEyeSpy,
    setFoundEyeSpy,
    removeFoundEyeSpy,
    myBadges,
    setInitialBadgeState,
    awardBronzeMedal,
    awardSilverMedal,
    awardGoldMedal,
  } = useContext(GlobalContext);
  const { task } = props;

  useEffect(() => {
    localStorage.setItem('found-eye-spies', JSON.stringify(foundEyeSpy));
    localStorage.setItem('my-badges', JSON.stringify(myBadges));
  });

  useEffect(() => {
    const eyeSpyBadges = badges[badges.findIndex(obj => obj.key === 'eye_spy')].badges;

    if (myBadges.eye_spy === undefined) {
      setInitialBadgeState('eye_spy');

      return;
    }

    if (foundEyeSpy.length >= eyeSpyBadges.bronze.score
      && myBadges.eye_spy.bronze !== true) {
      awardBronzeMedal('eye_spy');

      return;
    }

    if (foundEyeSpy.length >= eyeSpyBadges.silver.score
      && myBadges.eye_spy.silver !== true) {
      awardSilverMedal('eye_spy');

      return;
    }

    if (foundEyeSpy.length >= eyeSpyBadges.gold.score
      && myBadges.eye_spy.gold !== true) {
      awardGoldMedal('eye_spy');
    }
  });

  const seen = (id) => {
    if (foundEyeSpy.includes(id)) {
      const index = foundEyeSpy.indexOf(id);
      if (index !== -1) foundEyeSpy.splice(index, 1);
      removeFoundEyeSpy([id]);
      return;
    }

    if (foundEyeSpy.length === 0) {
      setFoundEyeSpy([id]);
      return;
    }

    setFoundEyeSpy([...foundEyeSpy, id]);
  };

  const taskHasBeenFound = id => foundEyeSpy.includes(id);

  return (
    <div className="eye-spy-task">
      <img src={task.image} alt="I spy" className="i-spy-image" />
      <p>{task.content}</p>
      {taskHasBeenFound(task.id)
        ? (
          <Button className="ml-4 multiple-choice-question__answer btn-spotted" onClick={() => seen(task.id)}>
            <span>Spotted!</span>
            <div className="oval">
              <FontAwesomeIcon icon="check" color="black" />
            </div>
          </Button>
        )
        : (
          <Button className="ml-4 btn-i-spy multiple-choice-question__answer" variant="outline-primary" onClick={() => seen(task.id)}>
            <span>I&apos;ve seen one!</span>
            <div className="blank_oval" />
          </Button>
        )}
    </div>
  );
};

EyeSpyTask.propTypes = {
  task: PropTypes.objectOf(PropTypes.any),
};

EyeSpyTask.defaultProps = {
  task: [],
};

export default EyeSpyTask;
